import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InitServ, NgOnDestroy, RenderComponentServ, SectionServ } from '../../Services';

@Component({
	selector: 'bk-embed-hiring-form',
	template: `<div [id]="secId" class="bkt-section" *ngIf="rcServ && pageSett && secId && pageSett[secId] && pageSett[secId][rcServ.statusType] && embedFormUrl">
		<iframe id="bkHiringIframe" [src]='embedFormUrl' style="border:none;" height="1200" width="100%" scrolling="yes"></iframe>
		<script [src]='embedJsUrl' defer></script>
	</div>`,
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class EmbedHiringFormComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	@Input() popupId: string = '';
	pageSett: any;
	embedFormUrl:any;
	embedJsUrl:any;
	varId: any;
	sectionData: any;
	constructor(public rcServ: RenderComponentServ, public secServ: SectionServ, private initServ: InitServ, public sanitizer: DomSanitizer) {
		this.embedJsUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://' + this.initServ.appData.primary_custom_domain + '/resources/embed.js');
	}

	ngOnInit() {
		if(this.popupId && this.rcServ.popupData && this.rcServ.popupData[this.popupId]){
			this.sectionData = this.rcServ.popupData[this.popupId];
		} else {
			this.sectionData = this.rcServ.pageData;
		}
		// Build section data
		if(this.secId && this.sectionData){
			this.pageSett = this.sectionData.section_settings;
			this.varId = this.pageSett[this.secId].variation_id;
			let secElem : any = null;
			if(this.sectionData && this.sectionData.sections){
				secElem = this.sectionData.sections[this.secId];
			}
			let url: any = null;
			if(secElem && secElem.form){
				let urlStatus: any = this.secServ.checkEleStatus(this.pageSett, secElem.form);
				if(urlStatus){
					url = this.pageSett[secElem.form]?.url;
				}
			}
			if(url){
				let formUrl = this.initServ.baseUrl + '/hiring/form/' + url;
				this.embedFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(formUrl);
				// setInterval(() => {
				// 	this.autoResize();
				// }, 8000);
				this.setHeight(1000);
				this.setHeight(3000);
				this.setHeight(5000);
				this.setHeight(8000);
			}
		}
	}
	/**
	 * Set the embed form height
	 * @param timeOut: time out time
	 */
	private setHeight(timeOut: number): void{
		setTimeout(() => {
			this.autoResize();
		}, timeOut);
	}
	/**
	 * Auto resize
	 */
	private autoResize() {
		let iframe = document.getElementById('bkHiringIframe') as HTMLIFrameElement;
		if (iframe && iframe.contentWindow && iframe.contentWindow.document && iframe.contentWindow.document.body) {
			let iframeHeight = iframe?.contentWindow?.document.body.scrollHeight;
			if(iframeHeight){
				iframe.height = (iframeHeight) + "px";
			}
			if (iframe && iframe.contentWindow && iframe.contentWindow.document.documentElement.scrollHeight > 0) {
				iframeHeight = iframe.contentWindow.document.documentElement.scrollHeight;
				if(iframeHeight){
					iframe.height = (iframeHeight) + "px";
				}
			}
		}
	}
}
