/* eslint-disable @typescript-eslint/ban-types */
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { XhrFactory } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
// External library
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'; // Translator
import { ToastrModule } from 'ngx-toastr'; // Toaster
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
// Environments
import { environment as env } from '../environments/environment';
// Modules
import { AppRoutingModule } from './app-routing.module'; // app routing module
import { CoreModule } from './Core/Core.module'; // Core module
import { GlobalDefaultModule, BookingFormModule, UserComponentsModule } from './Global';// Global module
import { ThemeElementsModule } from './ThemeElements/ThemeElements.module'; // Theme elements module
import { PopupsModule } from './Popups/Popups.module'; // Popups module

// Services
import { APIURL, InitServ, CustomTranslationLoader,EjabService,ChecklistService } from './Services';
import { AuthGuard } from './Core/_guards'; // Auth services
import { JwtInterceptor, BrowserXhrWithAbortError } from './Core/_helpers';
import { AuthServ } from './Core/_services';
// Constants
import { TOAST_OPTIONS } from './Constants';
// Components
import { AppComponent } from './app.component';
import { MainComponent } from './Main/Main.component';
import { BaseComponent } from './Base/Base.component';
import { QuoteRedirectionComponent } from './QuoteRedirection/QuoteRedirection.component';
// Session components
import { SessionComponent, SessionSocialComponent, LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent, PasswordProtectComponent, ReferralSignupComponent, SocialEmailPopupComponent, ReAuthPaymentComponent, InvoiceComponent, InvPaymentComponent, InvSidebarComponent, InvFromBkngTableComponent, InvPaymentLogsComponent, ContractPopupComponent, InvCustomFieldsComponent, AddPaymentComponent, LiveReviewsComponent, BookingReceiptComponent, ThirdPartyConnectComponent } from './Session';
import { ExistingPageComponent } from './ExistingPage/ExistingPage.component';

// configure Bugsnag asap
Bugsnag.start({ apiKey: env.bugsnagKey, appVersion: env.appVersion });
// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
	return new BugsnagErrorHandler()
}

// App load required data
export function appSiteData(initServ: InitServ): Function {
	return () => initServ.appSiteData();
}
export function appGlobalStyle(initServ: InitServ): () => void {
	return () => initServ.applyGlobalStyleApi();
}
export function pageData(initServ: InitServ): Function {
	return () => initServ.pageData();
}
export function appload(initServ: InitServ ): Function {
	return () => initServ.appload();
}
export function admnStngs(initServ: InitServ ): Function {
	return () => initServ.admnStngs()
}
export function appData(initServ: InitServ ): Function {
	initServ.appIpAddress();
	initServ.loadLanguages();
	initServ.bookingSpots();
	initServ.loadSocialKeys();
	return () => new Promise<any>((resolve) => { resolve(true) });
}
export function createTranslateLoader(http: HttpClient, APIURL: APIURL, InitServ: InitServ) {
	return new CustomTranslationLoader(http, APIURL, InitServ);
}
const disableAnimations = !('animate' in document.documentElement) || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));
@NgModule({
	declarations: [AppComponent, MainComponent, BaseComponent, QuoteRedirectionComponent, SessionComponent, SessionSocialComponent, LoginComponent, SignupComponent, ForgotPasswordComponent, ResetPasswordComponent, PasswordProtectComponent, ReferralSignupComponent, SocialEmailPopupComponent, ExistingPageComponent, ReAuthPaymentComponent, InvoiceComponent, InvPaymentComponent, InvSidebarComponent, ContractPopupComponent, InvPaymentLogsComponent, InvFromBkngTableComponent, InvCustomFieldsComponent, AddPaymentComponent, LiveReviewsComponent, BookingReceiptComponent, ThirdPartyConnectComponent],
	imports: [
		BrowserModule, HttpClientModule, CoreModule, BrowserAnimationsModule.withConfig({ disableAnimations }), MatDialogModule,
		AppRoutingModule, GlobalDefaultModule, PopupsModule, ThemeElementsModule, FormsModule, ReactiveFormsModule,
		ToastrModule.forRoot(TOAST_OPTIONS), MatDatepickerModule, MatNativeDateModule, BookingFormModule,UserComponentsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient, APIURL, InitServ]
			}
		}),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: env.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 10 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:10000'
		})
	],
	providers: [
		APIURL, InitServ,EjabService,ChecklistService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: appGlobalStyle, multi: true, deps: [InitServ] },
		{ provide: APP_INITIALIZER, useFactory: appSiteData, multi: true, deps: [InitServ] },
		{ provide: APP_INITIALIZER, useFactory: appload, multi: true,deps:[InitServ] },
		{ provide: APP_INITIALIZER, useFactory: admnStngs, multi: true,deps:[InitServ] },
		{ provide: APP_INITIALIZER, useFactory: pageData, multi: true, deps: [InitServ] },
		{ provide: APP_INITIALIZER, useFactory: appData, multi: true, deps: [InitServ] },
		AuthGuard, AuthServ,
		{provide: XhrFactory,useClass: BrowserXhrWithAbortError},
		{ provide: ErrorHandler, useFactory: errorHandlerFactory }
	],
	bootstrap: [AppComponent],
	entryComponents: [SocialEmailPopupComponent]
})
export class AppModule {
}
