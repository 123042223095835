import { Component, OnInit, Input, ViewEncapsulation, Self } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, LoaderServ, ApiServ, PopupServ, UtilServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP, PASSWORD_MIN_LENGTH } from '../../Constants';
import { takeUntil } from 'rxjs';

@Component({
	selector: 'bk-login',
	templateUrl: './Login.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class LoginComponent implements OnInit {

	// Variables
	@Input() secId: string = '';
	socialKeys: any = this.initServ.appSocialKeys; // App social keys
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		social: null,
		form: null
	}
	loginForm: FormGroup; // Login form
	redirectUrl: string = '';
	ratingToken: any;
	bid: any;
	userAccessToken: any;
	unRatedBkngs: any;
	// eslint-disable-next-line max-params
	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private authServ: AuthServ, private actRoute: ActivatedRoute, private loader: LoaderServ, @Self() private destroy: NgOnDestroy, private router: Router, public toastr: ToastrService, private apiServ: ApiServ, private popupServ: PopupServ,public utilServ: UtilServ) {
		this.ratingWithoutLogin();
		// Login user based on access token
		this.loginWithAccessToken();
		// get return url from route parameters or default to '/'
		this.redirectUrl = this.actRoute.snapshot.queryParams['returnUrl'];
		// Login form build
		this.loginForm = this.frmBldr.group({
			userName: [null, [Validators.required, Validators.pattern(EMAIL_REG_EXP)]],
			password: [null, [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]]
		});
	}

	ngOnInit(): void {
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	ratingWithoutLogin(): void {
		// Open the rating popup based on rating token and booking id
		this.ratingToken = this.getParam('ratingtoken');
		this.bid = this.getParam('bid');
		if(typeof(this.ratingToken) !== 'undefined' && typeof(this.bid) !== 'undefined' && this.ratingToken && this.bid && this.initServ.isRatingAllowed){
			this.getUnRatedBkngs(this.bid);
		}
	}
	getParam(param: string): any {
		return (this.actRoute.snapshot.params && this.actRoute.snapshot.params[param]) ? this.actRoute.snapshot.params[param] : null;
	}
	loginWithAccessToken(): void {
		this.userAccessToken = (this.actRoute.snapshot.params && this.actRoute.snapshot.params['access_token']) ? this.actRoute.snapshot.params['access_token'] : null;
		if(this.userAccessToken != undefined && this.userAccessToken && !this.initServ.theme){
			this.authServ.removeCurrentUser()
			if(this.initServ.ipAddress){
				this.loader.show()
				this.loginUsingToken();
			}
		} else{
			if(!this.utilServ.embedStatus && !this.initServ.theme){
				this.authServ.removeCurrentUser();
			}
		}
	}


	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.loginForm.controls;
	}
	/**
	 * Submit the login form
	 */
	public submitForm(): void {
		if(this.loginForm.valid){
			this.loginForm.controls['userName'].setValue((this.loginForm.controls['userName'].value).toLowerCase());
			this.authServ.login(this.loginForm.value, this.redirectUrl,'', true, true);
		} else{
			for(let i in this.loginForm.controls){
				this.loginForm.controls[i].markAsTouched();
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Login user using access token
	 */
	public async loginUsingToken(): Promise<any> {
		let status = await this.authServ.loginAsUser(this.userAccessToken,'');
		if(status){
			// this.toastr.success('Login successfully');
			this.toastr.success(this.initServ.appStr.toastr.loggedIn);
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['dashboard']]);
		} else {
			this.router.navigate(['/'+this.initServ.appDynamicRoutes['login']]);
		}
		this.loader.hide()
	}
	/**
	 * Get the un rated booking
	 */
	private getUnRatedBkngs(bid: any): void {
		let queryParams = {bid: bid};
		this.apiServ.callApiWithPathQueryVars('GET', 'NotRatedBkng', [0], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.notRatedBkngRes(res));
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private notRatedBkngRes(res:any){
		if(this.apiServ.checkAPIRes(res)){
			if (res.data && (res.data).length > 0) {
				this.unRatedBkngs = res.data;
				if (this.unRatedBkngs[0] && ((this.initServ.ratingAllowedFor).includes(this.unRatedBkngs[0]?.industry_id))) {
					let obj: any = {
						uid : this.unRatedBkngs[0].uid,
						provider_id : (this.unRatedBkngs[0].provider_info && (this.unRatedBkngs[0].provider_info).length> 0) ? this.unRatedBkngs[0].provider_info[0].id : 0,
						booking_id : this.unRatedBkngs[0]._id,
					};
					this.unRatedBkngs[0].auth_token = this.ratingToken
					this.popupServ.ratingPopup(this.unRatedBkngs[0], obj, true).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.ratingPopupRes(res));
				}
			}else{
				this.toastr.warning('The booking is already rated.');
			}
		}
	}
	/**
	 * On popup result callback method
	 * @param res popup res
	 * Popup response handler
	 */
	private ratingPopupRes(res: any): void {
		if(res) {
			if (res.type == 'submit' && this.checkShareToThirdPartySites() && this.checkShareReviewForServ(res)) {
				if (this.admnStngs.merchant_settings?.customers?.share_specific_review) {
					if (+res.rating.rating >= +this.admnStngs.merchant_settings?.customers?.specific_review) {
						//open share review popup
						this.popupServ.shareReviewPopup(res.booking, res.rating).pipe(takeUntil(this.destroy));
					}
				} else {
					//open share review popup
					this.popupServ.shareReviewPopup(res.booking, res.rating).pipe(takeUntil(this.destroy));
				}
			}
		}
	}
	private checkShareToThirdPartySites(): boolean {
		if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers && this.admnStngs.merchant_settings?.customers?.share_to_third_party_sites && this.admnStngs.merchant_settings?.customers?.share_to_third_party_sites == 'yes') {
			return true;
		}
		return false;
	}
	private checkShareReviewForServ(res: any): boolean {
		if (this.admnStngs.merchant_settings?.customers?.share_review_for_service && (this.admnStngs.merchant_settings.customers.share_review_for_service).length > 0 && (this.admnStngs.merchant_settings.customers.share_review_for_service).includes(res.booking.occurrence)) {
			return true;
		}
		return false;
	}
}
