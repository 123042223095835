<div [id]="isMultiStepHeader ? '' : (section?.id)" class="position-relative {{isMultiStepHeader?'multi-header-sum':''}}" *ngIf="summaryType && section">
	<ng-container *ngIf="summaryType == 'full'; else onlyTooltip">
		<ng-container *ngIf="couponData && couponData?.discount_bar_status && couponData?.discount_bar_status == 'yes'">
			<!-- Booking discount bar -->
			<bk-bkng-discount-bar [data]="couponData" layoutType="summary" [formLayout]="isMultiStepForm ? 'multistep_form' : 'form'"></bk-bkng-discount-bar>
		</ng-container>
		<div  id="accordion" class="tjs-accordion tjs-accordion__round-circle tjs-card card border tjs-summary tjs-rounded--lg rounded-5 mb-30 booking-summary-main-cls bk-form-summary">
			<div class="tjs-summary__header p-20 bk-mini-sum-header">
				<!-- Mobile open summary design -->
				<div class="p-0 accordion-button bk-form-summary-header tjs-summary-head" data-bs-toggle="collapse" aria-expanded="true" data-bs-target="#booking-summary" [ngClass]="(isMob && smryMobileScrlStatus) ? 'collapsed' : ''">
					<div class="accordion-text">
						<h5 class="accordion-header fw-bold mb-5" [id]="section?.title_id" [innerHTML]="section?.title | safeHtml"></h5>
						<bk-branding customWrapClass="fs-14 bk-form-summary-pwd" customAnchorClass="text-secondary"></bk-branding>
					</div>
				</div>
				<!-- Mobile collapsed summary design -->
				<div id="bk-mini-summary" *ngIf="smryMobileScrlStatus && isMob" class="p-0 accordion-button bk-form-summary-header tjs-summary-head--fixed" data-bs-toggle="collapse" aria-expanded="true" data-bs-target="#booking-summary" [ngClass]="(isMob && smryMobileScrlStatus) ? 'collapsed' : ''">
					<div class="accordion-text" *ngIf="(section?.short_summary_heading) || (siteData && siteData?.theme_settings && siteData?.theme_settings?.settings && siteData?.theme_settings?.settings?.powered_by_bookingkoala_branding)">
						<h5 *ngIf="section?.short_summary_heading" class="accordion-header fw-bold mb-5 bk-form-mini-sum-title" [id]="section?.short_summary_heading_id" [innerHTML]="section?.short_summary_heading | safeHtml"></h5>
						<bk-branding customWrapClass="fs-14 bk-form-summary-pwd" customAnchorClass="text-secondary"></bk-branding>
					</div>
					<!-- Mobile view -->
					<div id="summary-price-mini" class="booking-summary-boxes {{((section?.short_summary_heading) || (siteData && siteData?.theme_settings && siteData?.theme_settings?.settings && siteData?.theme_settings?.settings?.powered_by_bookingkoala_branding)) ? 'w-50' : ''}} text-end" *ngIf="isMob && smryMobileScrlStatus && section?.short_summary_value">
						<!-- Total without discount -->
						<h4 class="fw-bold mb-0 tjs-summary__total w-50 bk-form-sum-tbt-value " *ngIf="section?.total_before_tax">
							<bk-amount-display [amount]="totalWithoutTax" [isZero]="true"></bk-amount-display>
						</h4>
						<!-- Final amount -->
						<ng-container *ngIf="totalDisplayStatus">
							<h4 class="fw-bold mb-0 tjs-summary__total w-50 bk-form-sum-total-value" *ngIf="(bookingType == 'add' || (prefilledData?.status != 1 && prefilledData?.status != 2 && prefilledData?.status != 4)); else finalAmount">
								<bk-amount-display [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display>
							</h4>
							<ng-template #finalAmount>
								<div class="d-flex w-100" *ngIf="!prefilledData?.is_voided; else voidPayment">
									<h4 class="fw-bold mb-0 tjs-summary__total bk-form-sum-total-value">
										<span *ngIf="prefilledData?.final_amount; else zeroAmount">
											<bk-amount-display [amount]="prefilledData?.final_amount" [isZero]="true"></bk-amount-display>
										</span>
									</h4>
								</div>
								<ng-template #voidPayment>
									<h5 class="total-service text-white mb-0 bk-form-sum-total-value">
										<ng-container *ngTemplateOutlet="zeroAmount"></ng-container>
									</h5>
								</ng-template>
							</ng-template>
						</ng-container>
					</div>
				</div>
			</div>
			<div id="booking-summary" class="accordion-collapse collapse" data-bs-parent="#accordion" [ngClass]="(isMob && smryMobileScrlStatus) ? 'collapsed' : 'show'">
				<div class="tjs-summary__content px-20 fs-14">
					<ul class="tjs-list tjs-summary__list list-unstyled py-20 border-top border-bottom d-flex flex-column bk-form-sum-list">
						<!-- Industry -->
						<li *ngIf="!isMultiStepForm && section?.industry" [id]="section?.industry_id" [style.order]="bodyOrder.industry">
							<div class="tjs-summary__label bk-form-sum-label">
								{{section?.industry}}
							</div>
							<div class="tjs-summary__detail bk-form-sum-desc">
								{{industryName |translate}}
							</div>
						</li>
						<!-- Service -->
						<li *ngIf="!isMultiStepForm && section?.service" [id]="section?.service_id" [style.order]="bodyOrder.service">
							<div class="tjs-summary__label bk-form-sum-label">
								{{section?.service}}
							</div>
							<div class="tjs-summary__detail bk-form-sum-desc">
								{{serviceName | translate}}
							</div>
						</li>
						<!-- Frequency -->
						<li *ngIf="section?.frequency" [id]="section?.frequency_id" [style.order]="bodyOrder.frequency">
							<div class="tjs-summary__label bk-form-sum-label">
								{{section?.frequency}}
							</div>
							<div class="tjs-summary__detail bk-form-sum-desc">
								{{frequencyName | translate}}
							</div>
						</li>
						<!-- Form Params -->
						<ng-container *ngIf="section?.parameters" [ngSwitch]="formId">
							<ng-container *ngSwitchCase="1">
								<ng-container *ngIf="pricingParams && (pricingParams).length > 0">
									<ng-container *ngFor="let pricingParam of pricingParams; trackBy: utilServ.trackByFnIndex">
										<li *ngIf="pricingParam?.quantity" [id]="section?.parameters_id" [style.order]="bodyOrder.parameters">
											<div class="tjs-summary__label bk-form-sum-label">
												{{pricingParam?.cat_name | translate}}
											</div>
											<div class="tjs-summary__detail bk-form-sum-desc">
												{{pricingParam?.name | translate}}
											</div>
										</li>
									</ng-container>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="2">
								<ng-container *ngIf="items && items.length > 0">
									<ng-container *ngIf="hideItems else itemsWithPckgs">
										<ng-container *ngFor="let item of items; trackBy: utilServ.trackByFnIndex">
											<ng-container *ngIf="item && item?.packages && (item?.packages).length > 0">
												<li class="p-1" [id]="section?.parameters_id" [style.order]="bodyOrder.parameters">
													<div class="tjs-summary__label bk-form-sum-label">
														{{'Packages' | translate}}
													</div>
													<!-- Packages -->
													<ng-container *ngTemplateOutlet="itemPackages; context:{item:item}"></ng-container>
												</li>
											</ng-container>
										</ng-container>
									</ng-container>
									<ng-template #itemsWithPckgs>
										<ng-container *ngIf="items && items.length > 0">
											<li class="p-1"  *ngFor="let item of items; trackBy: utilServ.trackByFnIndex"  [id]="section?.parameters_id" [style.order]="bodyOrder.parameters">
												<div class="tjs-summary__label bk-form-sum-label">
													{{bkngFormServ.getElementName(item, 'items')  | translate}}
												</div>
												<!-- Packages -->
												<ng-container *ngTemplateOutlet="itemPackages; context:{item:item}"></ng-container>
											</li>
										</ng-container>
									</ng-template>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="3">
								<ng-container *ngIf="items && items.length > 0">
									<li class="p-1" *ngFor="let item of items; trackBy: utilServ.trackByFnIndex" [id]="section?.parameters_id" [style.order]="bodyOrder.parameters">
										<div class="tjs-summary__label bk-form-sum-label">
											{{item?.name  | translate}} ({{item?.quantity | translate}})
										</div>
										<div class="tjs-summary__detail bk-form-sum-desc" *ngIf="item?.addons">
											{{item?.addons}}
										</div>
									</li>
								</ng-container>
							</ng-container>
							<ng-container *ngSwitchCase="4">
								<li *ngIf="areaParams" [id]="section?.parameters_id" [style.order]="bodyOrder.parameters">
									<div class="tjs-summary__label bk-form-sum-label">
										{{areaParams?.name | translate}}
									</div>
									<div class="tjs-summary__detail bk-form-sum-desc">
										{{areaParams?.quantity | translate}}
									</div>
								</li>
							</ng-container>
						</ng-container>
						<!-- Extras -->
						<li *ngIf="extras && extras.length > 0 && section?.extras" [id]="section?.extras_id" [style.order]="bodyOrder.extras">
							<ul class="tjs-list list-unstyled p-0 w-100">
								<li [ngClass]="{'d-flex' : !isExtraWithPrice}">
									<div class="tjs-summary__label bk-form-sum-label" [ngClass]="{'tjs-pseudo-none fw-bold mb-5' : isExtraWithPrice}">{{section?.extras}}</div>
									<div>
										<ul class="tjs-list tjs-summary__list list-unstyled w-100 tjs-summary-extras d-flex flex-column gap-5-imp" [ngClass]="{'ps-0': !isExtraWithPrice}">
											<li *ngFor="let extra of extras">
												<ng-container *ngIf="isExtraWithPrice; else extraWithoutPrice">
													<div class="tjs-summary__label bk-form-sum-label">
														{{extra?.name | translate}}
														<span class="summary-ex-counter ms-5" *ngIf="extra?.quantity && extra?.quantity > 1">({{extra?.quantity | translate}})</span>
													</div>
													<div class="tjs-summary__detail bk-form-sum-desc">
														<bk-amount-display [amount]="extra?.price" [isZero]="true"></bk-amount-display>
													</div>
												</ng-container>
												<ng-template #extraWithoutPrice>
													<div class="tjs-summary__detail bk-form-sum-desc">
														{{extra?.name | translate}}
														<span class="summary-ex-counter ms-5" *ngIf="extra?.quantity && extra?.quantity > 1">({{extra?.quantity | translate}})</span>
													</div>
												</ng-template>
											</li>
										</ul>
									</div>
								</li>
							</ul>
						</li>
						<!-- Excludes -->
						<li *ngIf="excludes && excludes.length > 0 && section?.excludes" [id]="section?.excludes_id" [style.order]="bodyOrder.excludes">
							<ul class="tjs-list list-unstyled p-0 w-100">
								<li [ngClass]="{'d-flex' : !isExcludeWithPrice}">
									<div class="tjs-summary__label bk-form-sum-label mb-5" [ngClass]="{'tjs-pseudo-none fw-bold' : isExcludeWithPrice}">{{section?.excludes}}</div>
									<ul class="tjs-list tjs-summary__list list-unstyled w-100 tjs-summary-extras d-flex flex-column gap-5-imp" [ngClass]="{'ps-0': !isExcludeWithPrice}">
										<li *ngFor="let exclude of excludes">
											<ng-container *ngIf="isExcludeWithPrice; else excludeWithoutPrice">
												<div class="tjs-summary__label bk-form-sum-label">
													{{exclude?.name | translate}}
													<span class="summary-ex-counter ms-5" *ngIf="exclude?.quantity && exclude?.quantity > 1">({{exclude?.quantity | translate}})</span>
												</div>
												<div class="tjs-summary__detail bk-form-sum-desc">
													- <bk-amount-display [amount]="exclude?.price" [isZero]="true"></bk-amount-display>
												</div>
											</ng-container>
											<ng-template #excludeWithoutPrice>
												<div class="tjs-summary__detail bk-form-sum-desc">
													{{exclude?.name | translate}}
													<span class="summary-ex-counter ms-5" *ngIf="exclude?.quantity && exclude?.quantity > 1">({{exclude?.quantity | translate}})</span>
												</div>
											</ng-template>
										</li>
									</ul>
								</li>
							</ul>
						</li>
						<!-- Professionals -->
						<ng-container *ngIf="(admnStngs && admnStngs.merchant_settings && admnStngs.merchant_settings?.bookings && admnStngs.merchant_settings?.bookings?.show_professional_count && admnStngs.merchant_settings?.bookings?.show_professional_count !='no') || !admnStngs.merchant_settings?.bookings?.show_professional_count">
							<ng-container *ngIf="(isStepOneValid || !prefilledData); else stepOne">
								<ng-container *ngIf="formValues?.provider_details && (formValues?.provider_details).length > 0">
									<li *ngIf="section?.professionals" [id]="section?.professionals_id" [style.order]="bodyOrder.professionals">
										<div class="tjs-summary__label bk-form-sum-label">
											{{section?.professionals}}
										</div>
										<div class="tjs-summary__detail bk-form-sum-desc">
											<ng-container *ngIf="(formValues?.provider_details).length == 1; else pairProvs">
												<ng-container *ngIf="formValues?.provider_details[0].provider_type=='team'; else singleProv">
													{{formValues?.provider_details[0].members.length}}
												</ng-container>
												<ng-template #singleProv> 1 </ng-template>
											</ng-container>
											<ng-template #pairProvs>
												{{(formValues?.provider_details).length}}
											</ng-template>
										</div>
									</li>
								</ng-container>
							</ng-container>
							<!--if on step 1 -->
							<ng-template #stepOne>
								<ng-container *ngIf="(prefilledData?.provider_category != 'team' && prefilledData?.provider_wages && (prefilledData?.provider_wages).length > 0) || (prefilledData?.provider_category == 'team' &&  prefilledData?.members && (prefilledData?.members).length > 0)">
									<li *ngIf="section?.professionals" [id]="section?.professionals_id" [style.order]="bodyOrder.professionals">
										<div class="tjs-summary__label bk-form-sum-label">
											{{section?.professionals}}
										</div>
										<div class="tjs-summary__detail bk-form-sum-desc">
											<ng-container *ngIf="prefilledData?.provider_category != 'team' && prefilledData?.provider_wages && (prefilledData?.provider_wages).length > 0; else teamProvs">
												<span>{{(prefilledData?.provider_wages).length}}</span>
											</ng-container>
											<ng-template #teamProvs>
												<ng-container *ngIf="prefilledData?.provider_category == 'team' &&  prefilledData?.members && (prefilledData?.members).length > 0 ">
													<span>{{(prefilledData?.members).length}}</span>
												</ng-container>
											</ng-template>
										</div>
									</li>
								</ng-container>
							</ng-template>
						</ng-container>
						<!-- Job length -->
						<li *ngIf="isJobLength && section?.length" [id]="section?.length_id" [style.order]="bodyOrder.length">
							<div class="tjs-summary__label bk-form-sum-label">
								{{section?.length}}
							</div>
							<div class="tjs-summary__detail bk-form-sum-desc">
								<ng-container *ngIf="formValues?.adjust_time; else lengthBlock">
									<ng-container *ngIf="formValues?.adjusted_time; else noTime">
										<span *ngIf="adjustedTimeHours != 0">
											{{adjustedTimeHours}} {{'Hr' | translate}} {{adjustedTimeMin}} {{'Min' | translate}}
										</span>
										<span *ngIf="adjustedTimeHours == 0">{{adjustedTimeMin}} {{'Min' | translate}}</span>
									</ng-container>
									<ng-template #noTime>
										0 {{'Min' | translate}}
									</ng-template>
								</ng-container>
								<ng-template #lengthBlock>
									<span *ngIf="bookingTimeHours != 0">{{bookingTimeHours}} {{'Hr' | translate}} {{bookingTimeMin}} {{'Min' | translate}}</span>
									<span *ngIf="bookingTimeHours == 0">{{bookingTimeMin}} {{'Min' | translate}}</span>
								</ng-template>
								<!-- Tooltip -->
								<bk-tooltip *ngIf="selectedServiceType && selectedServiceType?.enable_length_tooltip && selectedServiceType?.enable_length_tooltip_text" customClass="bk-tooltip" [content]="selectedServiceType.enable_length_tooltip_text | translate"></bk-tooltip>
							</div>
						</li>
						<!-- Location -->
						<ng-container *ngIf="!isMultiStepForm && selectedAddr && selectedAddr?.address">
							<!-- Address -->
							<li *ngIf="section?.location" [id]="section?.location_id" [style.order]="bodyOrder.location">
								<div class="tjs-summary__label bk-form-sum-label">
									{{section?.location}}
								</div>
								<div class="tjs-summary__detail bk-form-sum-desc">
									{{selectedAddr?.address}}
								</div>
							</li>
							<!-- APT -->
							<ng-container *ngIf="formValues?.location_type == 'SA' && formValues?.customer?.apt">
								<li *ngIf="section?.apt" [id]="section?.apt_id" [style.order]="bodyOrder.apt">
									<div class="tjs-summary__label bk-form-sum-label">
										{{section?.apt}}
									</div>
									<div class="tjs-summary__detail bk-form-sum-desc">
										{{formValues?.customer?.apt}}
									</div>
								</li>
							</ng-container>
						</ng-container>
						<!-- Custom fields -->
						<ng-container *ngIf="bkngCustSecServ.priceableCustFields && (bkngCustSecServ.priceableCustFields).length > 0 && bkngFormServ?.formMetaData && bkngFormServ?.formMetaData?.sum_custom_fields_status">
							<ng-container *ngFor="let custGroup of bkngCustSecServ.priceableCustFields">
								<ng-container *ngIf="custFieldGroupStatus[custGroup.id]">
									<ng-container *ngIf="!custGroup.isIndividual && bkngFormServ?.formMetaData?.sum_custom_fields_status?.sec[custGroup.id]['status']; else individualBlock">
										<li [style.order]="bodyOrder['sum_custom_fields_'+custGroup.id]">
											<ul class="list-unstyled p-0 w-100">
												<li>
													<div class="tjs-summary__label bk-form-sum-label mb-5 tjs-pseudo-none fw-bold">{{custGroup?.name | translate}}</div>
													<div>
														<ul class="list-unstyled w-100 tjs-summary__extras tjs-summary__list d-flex flex-column gap-5-imp" >
															<ng-container *ngFor="let field of custGroup.custFields">
																<ng-container *ngIf="bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id] && bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id]['status']">
																	<li *ngIf="(!field.isCheckbox && customFieldsGroup.controls[field.ctrl].value && customFieldsGroup.controls[field.ctrl].value != '' && customFieldsGroup.controls[field.ctrl].value != null && customFieldsGroup.controls[field.ctrl].value != 'null') || (field.isCheckbox && cfCheckboxGroup.controls[field.ctrl].value && (cfCheckboxGroup.controls[field.ctrl].value).length > 0)">
																		<div class="tjs-summary__label ps-15 bk-form-sum-label">
																			{{field?.name | translate}}
																		</div>
																		<div class="tjs-summary__detail bk-form-sum-desc">
																			<span>
																				<ng-container *ngIf="field.isCheckbox; else nonCheckboxField">
																					<ng-container *ngIf="cfCheckboxGroup.controls[field.ctrl].value && (cfCheckboxGroup.controls[field.ctrl].value).length > 0">
																						<ng-container *ngFor="let val of cfCheckboxGroup.controls[field.ctrl].value; let isLast=last">
																							{{bkngCustSecServ.checkboxOptNames[val] | translate}}{{isLast ? '' : ', '}}
																						</ng-container>
																					</ng-container>
																				</ng-container>
																				<ng-template #nonCheckboxField>
																					{{customFieldsGroup.controls[field.ctrl].value | translate}}
																				</ng-template>
																			</span>
																		</div>
																	</li>
																</ng-container>
															</ng-container>
														</ul>
													</div>
												</li>
											</ul>
										</li>
									</ng-container>
									<ng-template #individualBlock>
										<ng-container *ngFor="let field of custGroup.custFields">
											<ng-container *ngIf="bkngFormServ?.formMetaData && bkngFormServ?.formMetaData?.sum_custom_fields_status && bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields && bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id] && bkngFormServ?.formMetaData?.sum_custom_fields_status?.fields[field.id]['status']">
												<li *ngIf="(!field.isCheckbox && customFieldsGroup.controls[field.ctrl].value && customFieldsGroup.controls[field.ctrl].value != '') || (field.isCheckbox && cfCheckboxGroup.controls[field.ctrl].value && (cfCheckboxGroup.controls[field.ctrl].value).length > 0)" [style.order]="bodyOrder['sum_custom_fields_'+custGroup.id]">
													<div class="tjs-summary__label bk-form-sum-label">
														{{field?.name | translate}}
													</div>
													<div class="tjs-summary__detail bk-form-sum-desc">
														<span>
															<ng-container *ngIf="field.isCheckbox; else nonCheckboxField">
																<ng-container *ngIf="cfCheckboxGroup.controls[field.ctrl].value && (cfCheckboxGroup.controls[field.ctrl].value).length > 0">
																	<ng-container *ngFor="let val of cfCheckboxGroup.controls[field.ctrl].value; let isLast=last">
																		{{bkngCustSecServ.checkboxOptNames[val] | translate}}{{isLast ? '' : ', '}}
																	</ng-container>
																</ng-container>
															</ng-container>
															<ng-template #nonCheckboxField>
																{{customFieldsGroup.controls[field.ctrl].value | translate}}
															</ng-template>
														</span>
													</div>
												</li>
											</ng-container>
										</ng-container>
									</ng-template>
								</ng-container>
							</ng-container>
						</ng-container>
					</ul>
				</div>
				<div class="tjs-summary__footer d-flex align-items-center border-0 px-20 py-15 bg-transparent flex-column">
					<ng-container *ngIf="section?.total_before_tax">
						<div class="d-flex flex-wrap align-items-center flex-grow-1 bk-form-sum-tbt w-100" [id]="section?.total_before_tax_id" [style.order]="paymentOrder.total_before_tax">
							<h6 class="fw-bold mb-0 w-50 text-wrap pe-10 text-muted bk-form-sum-tbt-label">
								{{section?.total_before_tax}}
							</h6>
							<h6 class="fw-bold mb-0 w-50 text-dark text-end bk-form-sum-tbt-value">
								<bk-amount-display [amount]="totalWithoutTax" [isZero]="true"></bk-amount-display>
							</h6>
						</div>
					</ng-container>
					<ng-container *ngIf="totalDisplayStatus">
						<div class="d-flex flex-grow-1 bk-form-sum-total w-100" [id]="section?.total_id" [style.order]="paymentOrder.total">
							<h4 class="fw-bold mb-0 w-50 text-wrap pe-10 text-secondary bk-form-sum-total-label">
								{{(section?.total) ? section?.total : totalLable}}
							</h4>
							<h4 class="fw-bold mb-0 tjs-summary__total w-50 text-secondary text-end bk-form-sum-total-value" *ngIf="(bookingType == 'add' || (prefilledData?.status != 1 && prefilledData?.status != 2 && prefilledData?.status != 4)); else ElseFinalAmount">
								<bk-amount-display [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display>
							</h4>
							<ng-template #ElseFinalAmount>
								<ng-container *ngIf="(!prefilledData?.is_voided && prefilledData?.final_amount); else zeroAmount">
									<h4 class="fw-bold mb-0 tjs-summary__total w-50 text-secondary text-end bk-form-sum-total-value">
										<bk-amount-display *ngIf="prefilledData?.final_amount" [amount]="prefilledData?.final_amount" [isZero]="true"></bk-amount-display>
									</h4>
								</ng-container>
							</ng-template>
						</div>
					</ng-container>
					<ng-container *ngIf="bookingType == 'add'">
						<ng-container *ngIf="selectedFrequency?.form_frequency_data?.occurence_time && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'">
							<div *ngIf="section?.next_n_apt_total" class="d-flex flex-wrap align-items-center flex-grow-1 w-100 recurr-total bk-form-sum-nxt-apt" [id]="section?.next_n_apt_total_id" [style.order]="paymentOrder.next_n_apt_total">
								<h5 class="fw-bold w-60 text-wrap fs-14 text-muted bk-form-sum-nxt-apt-label mb-0">{{nextNAptTotal}}</h5>
								<h5 class="fw-bold w-40 fs-14 text-dark text-end bk-form-sum-nxt-apt-value mb-0">
									<bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
								</h5>
							</div>
							<div *ngIf="section?.rec_total_after_n_apt" class="d-flex w-100 flex-wrap align-items-center flex-grow-1 recurr-total bk-form-sum-rec-apt" [id]="section?.rec_total_after_n_apt_id" [style.order]="paymentOrder.rec_total_after_n_apt">
								<h5 class="fw-bold w-60 text-wrap fs-14 text-muted bk-form-sum-rec-apt-label mb-0">{{recTotalAfterNApt}}</h5>
								<h5 class="fw-bold w-40 fs-14 text-body text-end text-dark bk-form-sum-rec-apt-value mb-0">
									<bk-amount-display [amount]="afterNthAptFinalAmount" [isZero]="true"></bk-amount-display>
								</h5>
							</div>
						</ng-container>
						<ng-container *ngIf="!(selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring') && (selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) ||  (BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking')))">
							<div *ngIf="section?.first_apt_total" class="d-flex flex-wrap align-items-center w-100 flex-grow-1 bk-form-sum-fst-apt" [id]="section['first_apt_total_id']" [style.order]="paymentOrder['first_apt_total']">
								<h6 class="fw-bold w-50 text-wrap text-muted bk-form-sum-fst-apt-label">{{section['first_apt_total']}}</h6>
								<h6 class="fw-bold w-50 text-dark text-end bk-form-sum-fst-apt-value">
									<bk-amount-display [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
								</h6>
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngTemplateOutlet="paymentSummary"></ng-container>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-template #onlyTooltip>
		<ng-container *ngTemplateOutlet="paymentSummary"></ng-container>
	</ng-template>
	<!-- Loader -->
	<bk-app-loader [loaderId]="loaderId"></bk-app-loader>
</div>

<!-- Empty amount -->
<ng-template #zeroAmount>
	<bk-amount-display [isZero]="true"></bk-amount-display>
</ng-template>

<!-- Payment Summary -->
<ng-template #paymentSummary>
	<div class="d-flex w-100 {{isMultiStepHeader ? 'justify-content-sm-end':'justify-content-end '}}" *ngIf="((section?.learn_more) || (isMultiStepHeader && parentSection?.header_summary && parentSection?.header_summary?.learn_more)) && isPaySummVisible" [style.order]="paymentOrder?.learn_more">
	<div class="tjs-summary__more bk-form-sum-learnmore" [id]="isMultiStepHeader ? (parentSection?.header_summary?.learn_more_id) : (section?.learn_more_id)">
		<ng-container *ngIf="isMultiStepHeader; else sideLearnMore">
			<button type="button" class="fs-14 bg-transparent border-0 tjs-highlight-link position-relative text-secondary fw-bold p-0-imp bk-form-sum-learnmore-label" *ngIf="parentSection?.header_summary?.learn_more">
				{{parentSection?.header_summary?.learn_more}}
			</button>
		</ng-container>
		<ng-template #sideLearnMore>
			<button type="button" class="fs-14 bg-transparent border-0 tjs-highlight-link position-relative text-secondary fw-bold p-0-imp bk-form-sum-learnmore-label" *ngIf="section?.learn_more">
				{{section?.learn_more}}
			</button>
		</ng-template>
		<ng-container *ngIf="bookingType == 'add' || prefilledData">
			<div class="tjs-summary__box p-10 rounded tjs-scrollbar tjs-overflow-scroll tjs-scroll-maxheight" *ngIf="(bookingType == 'add' || (prefilledData.status != 1 && prefilledData.status != 2 && prefilledData.status != 4)); else ElseSummaryBlock">
				<p class="d-flex">
					<span class="tjs-summary__more-label" applyOn translate>Service Total</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="updatedDisplayServicePrice" [isZero]="true"></bk-amount-display>
				</p>
				<!-- Before freq disc priceable custom fields -->
				<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'include_in_freq_disc'}"></ng-container>

				<p class="d-flex mt-10" *ngIf="BKFrm?.value?.referral_discount">
					<span class="tjs-summary__more-label" applyOn translate>Referral Discount</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="BKFrm?.value?.referral_discount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10" *ngIf="BKFrm?.value?.same_day_booking && BKFrm?.value?.expedited_amount && !excludeExpeditedAmountValue">
					<span class="tjs-summary__more-label" applyOn translate>Expedited Amount</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="BKFrm?.value?.expedited_amount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10" *ngIf="displayFrequencyDiscount > 0">
					<span class="tjs-summary__more-label" applyOn translate>Frequency Discount</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayFrequencyDiscount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10" *ngIf="BKFrm?.value?.exempt_extras_price && BKFrm?.value?.exempt_extras_price > 0">
					<span class="tjs-summary__more-label" applyOn translate>Exempted Extras</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="BKFrm?.value?.exempt_extras_price" [isZero]="true"></bk-amount-display>
				</p>
				<!-- Exempted priceable custom fields -->
				<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'exempt_from_freq_disc'}"></ng-container>

				<p class="d-flex mt-10" *ngIf="displaySpotDiscount > 0">
					<span class="tjs-summary__more-label" applyOn translate>Spot Discount</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displaySpotDiscount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10" *ngIf="displayCouponDiscount > 0">
					<span class="tjs-summary__more-label" applyOn translate>Coupon Discount</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayCouponDiscount" [isZero]="true"></bk-amount-display>
				</p>
				<!-- Add service fee here if service fee is taxable. -->
				<p class="d-flex mt-10" *ngIf="isServiceFeeTaxable && isServiceFeeTaxable == 'yes' && BKFrm?.value?.service_fee && !excludeServiceFeeValue">
					<span class="tjs-summary__more-label" applyOn>{{(initServ?.serviceFeeLabel ? initServ?.serviceFeeLabel : 'Service Fee' | translate )}}</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayServiceFee" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10">
					<span class="tjs-summary__more-label" applyOn translate>Discounted Total</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayDiscountedAmount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex mt-10" *ngIf="BKFrm?.value?.adjust_price">
					<label class="tjs-summary__more-label m-0" applyOn translate>Adjusted Amount</label>
					<span class="tjs-summary__more-detail" *ngIf="BKFrm?.value?.adjusted_price">
						<bk-amount-display [amount]="BKFrm?.value?.adjusted_price" [isZero]="true"></bk-amount-display>
					</span>
					<span class="tjs-summary__more-detail" *ngIf="!BKFrm?.value?.adjusted_price">
						<ng-container class="tjs-summary__more-detail" *ngTemplateOutlet="zeroAmount"></ng-container>
					</span>
				</p>
				<!-- After discounted total priceable custom fields -->
				<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'after_discounted_total'}"></ng-container>

				<p class="d-flex mt-10" *ngIf="bookingTaxRate && !exemptSalesTaxValue">
					<span class="tjs-summary__more-label" applyOn>{{taxLabel}}</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displaySaleTax" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex" *ngIf="BKFrm?.value?.tip?.total_amount && BKFrm?.value?.tip?.total_amount > 0">
					<span class="tjs-summary__more-label" applyOn translate>Tip</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayTipsAmount" [isZero]="true"></bk-amount-display>
				</p>
				<p class="d-flex" *ngIf="BKFrm?.value?.parking?.total_amount && BKFrm?.value?.parking?.total_amount > 0">
					<span class="tjs-summary__more-label" applyOn translate>Parking</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="BKFrm?.value?.parking?.total_amount" [isZero]="true"></bk-amount-display>
				</p>
				<!-- Add service fee here if service fee is not taxable. -->
				<p class="d-flex" *ngIf="(!isServiceFeeTaxable || (isServiceFeeTaxable == 'no')) &&  BKFrm?.value?.service_fee && !excludeServiceFeeValue">
					<span class="tjs-summary__more-label" applyOn>{{(initServ?.serviceFeeLabel ? initServ?.serviceFeeLabel : 'Service Fee' | translate )}}</span>
					<bk-amount-display class="tjs-summary__more-detail" [amount]="displayServiceFee" [isZero]="true"></bk-amount-display>
				</p>
				<!-- Non taxable priceable custom fields -->
				<ng-container *ngTemplateOutlet="priceableCustomField; context:{type: 'non_taxable'}"></ng-container>

				<p class="d-flex tjs-summary__more-footer position-sticky bottom-0" *ngIf="subTotalLabel">
					<span class="tjs-summary__more-label fw-bold" applyOn>
						{{subTotalLabel}}
					</span>
					<bk-amount-display class="tjs-summary__more-detail fw-bold" [amount]="displayTotal" [isZero]="true"></bk-amount-display>
				</p>
				<ng-container *ngIf="displayGiftCardAmount || displayReferralDiscount">
					<p class="d-flex mt-10" *ngIf="displayGiftCardAmount">
						<span class="tjs-summary__more-label" applyOn translate>Gift Card Discount</span>
						<bk-amount-display class="tjs-summary__more-detail" [amount]="displayGiftCardAmount" [isZero]="true"></bk-amount-display>
					</p>
					<p class="d-flex mt-10" *ngIf="displayReferralDiscount">
						<span class="tjs-summary__more-label" applyOn translate>Referral Discount</span>
						<bk-amount-display class="tjs-summary__more-detail" [amount]="displayReferralDiscount" [isZero]="true"></bk-amount-display>
					</p>
					<p class="d-flex" *ngIf="firstAptTotalLabel">
						<label class="tjs-summary__more-label m-0 l-highlight" applyOn>{{firstAptTotalLabel}}</label>
						<bk-amount-display class="tjs-summary__more-detail l-highlight " [amount]="displayFinalAmount" [isZero]="true"></bk-amount-display>
					</p>
				</ng-container>
				<ng-container *ngIf="bookingType == 'add'">
					<ng-container *ngIf="selectedFrequency?.form_frequency_data?.occurence_time && selectedFrequency?.form_frequency_data?.occurence_time == 'recurring' && BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings != 'first_booking' && BKFrm?.value?.coupon?.apply_on_bookings != 'all_recurring'; else WithoutCoupon">
						<ng-container *ngIf="isMultiStepHeader else sideBar">
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="parentSection?.header_summary?.next_n_apt_total">
								<span class="tjs-summary__more-label" applyOn>{{multiNextNAptTotal}}</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="parentSection?.header_summary?.rec_total_after_n_apt">
								<span class="tjs-summary__more-label" applyOn>{{multiRecTotalAfterNApt}}</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="afterNthAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
						</ng-container>
						<ng-template #sideBar>
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="section?.next_n_apt_total">
								<span class="tjs-summary__more-label" applyOn>{{nextNAptTotal}}</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="section?.rec_total_after_n_apt">
								<span class="tjs-summary__more-label" applyOn>{{recTotalAfterNApt}}</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="afterNthAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
						</ng-template>
					</ng-container>
					<ng-template #WithoutCoupon>
						<ng-container *ngIf="isMultiStepHeader; else firstApt">
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="parentSection?.header_summary?.first_apt_total && selectedFrequency?.form_frequency_data.occurence_time && selectedFrequency?.form_frequency_data.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) || (BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking'))">
								<span class="tjs-summary__more-label" applyOn>
									{{parentSection?.header_summary?.first_apt_total}}
								</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
						</ng-container>
						<ng-template #firstApt>
							<p class="d-flex mb-0 tjs-summary__more-footer" *ngIf="section?.first_apt_total && selectedFrequency?.form_frequency_data.occurence_time && selectedFrequency?.form_frequency_data.occurence_time == 'recurring' && (selectedFrequency?.form_frequency_data?.frequency_discount_on_bookings == 'exclude-first-booking' || (selectedFrequency?.form_frequency_data?.shorter_job_length && selectedFrequency?.form_frequency_data?.shorter_job_length == 'yes' && selectedFrequency?.form_frequency_data?.exclude_first_apt_for_shorter_length) ||  (BKFrm?.value?.coupon?.apply_on_bookings && BKFrm?.value?.coupon?.apply_on_bookings == 'first_booking'))">
								<span class="tjs-summary__more-label" applyOn>
									{{section?.first_apt_total}}
								</span>
								<bk-amount-display class="tjs-summary__more-detail" [amount]="firstRecAptFinalAmount" [isZero]="true"></bk-amount-display>
							</p>
						</ng-template>
					</ng-template>
				</ng-container>
			</div>
			<ng-template #ElseSummaryBlock>
				<bk-charged-payment-summary [prefilledData]="prefilledData" [section]="section" [selectedServiceType]="selectedServiceType"></bk-charged-payment-summary>
			</ng-template>
		</ng-container>
	</div>
</div>
</ng-template>

<!-- Packages template -->
<ng-template #itemPackages let-item="item">
	<div class="tjs-summary__detail bk-form-sum-desc" *ngIf="item.packages && (item.packages).length > 0">
		<div *ngFor="let package of item.packages; trackBy: utilServ.trackByFnIndex">
			{{bkngFormServ.getElementName(package, 'package') | translate}} ({{package.quantity | translate}})
			<div class="fs-13 mt-5 text-body" *ngIf="package.package_addons && (package.package_addons).length > 0">
				<ng-container *ngFor="let pckgAddon of package.package_addons; let i = index; trackBy: utilServ.trackByFnIndex">
					<ng-container *ngIf="settingsParams?.package_addons && settingsParams?.package_addons[pckgAddon?.id]">
						<ng-container *ngIf="(i+1) == (package.package_addons).length; else elseAddon">
							{{utilServ.getFormParamName(settingsParams?.package_addons[pckgAddon?.id]) | translate}} ({{pckgAddon.quantity | translate}})
						</ng-container>
						<ng-template #elseAddon>
							{{utilServ.getFormParamName(settingsParams?.package_addons[pckgAddon?.id]) | translate}} ({{pckgAddon.quantity | translate}}),
						</ng-template>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</ng-template>

<!-- Priceable custom fields template-->
<ng-template #priceableCustomField let-type="type">
	<ng-container *ngIf="priceableFieldNamePriceObj && priceableFieldNamePriceObj?.[type]">
		<ng-container *ngFor="let field of priceableFieldNamePriceObj[type]">
			<p class="d-flex mt-10">
				<span class="tjs-summary__more-label" applyOn>{{field?.fieldName | translate}}</span>
				<bk-amount-display class="tjs-summary__more-detail" [amount]="field.fieldPrice" [isZero]="true"></bk-amount-display>
			</p>
		</ng-container>
	</ng-container>
</ng-template>
