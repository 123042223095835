/* eslint-disable complexity */
import { Component, OnInit, ViewEncapsulation, ViewContainerRef, Self, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { takeUntil } from "rxjs/internal/operators/takeUntil";
// Services
import { NgOnDestroy, RenderComponentServ, ApiServ, InitServ, UtilServ, CookieServ, PopupServ, CacheService } from '../Services';
@Component({
	selector: 'bk-main',
	templateUrl: './Main.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy]
})
export class MainComponent implements OnInit, OnDestroy {
	// Variables
	appRouteStatus: boolean = false;
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	cookieName: string = '';
	unRatedBkngs: any;
	queryParams: any = null;
	_routeListener: any;
	isParentSlug: boolean = false;
	appRouteSlugs: string[] = ['login', 'signup', 'reset-password', 'add-payment'];
	// eslint-disable-next-line max-params
	constructor(private vcRef: ViewContainerRef, public rcServ: RenderComponentServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private initServ: InitServ, private router: Router, public utilServ: UtilServ, private actRoute: ActivatedRoute, private cookieServ: CookieServ, private popupServ: PopupServ, private cacheServ: CacheService) {
		// App route checker
		this._routeListener = this.router.events.pipe(takeUntil(this.destroy)).subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				let currentRoute: any = this.actRoute;
				let parentRoute: any = currentRoute.firstChild;
				let parentPath = (parentRoute && parentRoute.routeConfig && parentRoute.routeConfig?.path) && parentRoute.routeConfig?.path;
				// Child route
				if (currentRoute) {
					while (currentRoute.firstChild) {
						currentRoute = currentRoute.firstChild;
					}
				}
				let pagePath: any = (ev.url.split(/[?#]/))[0];
				let path: any;
				if (currentRoute && currentRoute.routeConfig && currentRoute.routeConfig?.path) {
					let parent: any = parentPath ? `/${parentPath}` : '';
					path = parent + `/${currentRoute.routeConfig?.path}`
				}
				if (!path || path == '/**') {
					path = pagePath;
				}

				this.appRouteStatus = (this.initServ.appRoutes).includes(path);
				let slug: any = pagePath.substring(1);
				slug = slug ? slug : 'home';
				// This code only for embed and if login/signup/reset-password url change by theme builder
				if (this.appRouteStatus && this.appRouteSlugs.includes(slug) && this.initServ.appDynamicRoutes && this.initServ.appDynamicRoutes[slug] != path) {
					this.appRouteStatus = false;
				}
				/**
				 * When current route exist in the ROUTE_URL that contains the inner pages, then change the slug to its parent slug and update the status of appRouteStatus & isParentSlug.
				 * Inner pages which are not customized from theme-builder but need style & data from the parent slug & further this check is associated with the routes in the *onResultCallback()*, if parentSlug is true.
				 */
				if (this.initServ.changeCurrentRouteSlug(slug) != null) {
					slug = this.initServ.changeCurrentRouteSlug(slug);
					this.appRouteStatus = false;
					this.isParentSlug = true;
				}
				if (!this.appRouteStatus) {
					if (this.initServ.theme) {
						this.buildPage(slug, true);
					} else {
						this.buildPage(slug);
					}
				} else {
					// Set the page data
					this.utilServ.setPageSett(0, null);
					// Page title for inner pages
					this.rcServ.setPageTitle();
				}
				// if you need to scroll back to top, here is the right place
				setTimeout(() => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}, 1000);
			}
		});
		// Append style in case of embed forms
		if (this.utilServ.embedStatus && !this.initServ.theme) {
			let styleEl = document.getElementsByTagName('style');
			if (styleEl && styleEl.length > 0) {
				let style = 'body,html{background:transparent;}'
				styleEl[0].appendChild(document.createTextNode(style));
			}
		}
		// Global un rated booking popup
		// On reauth-card page not open the rating popup
		let lastRoute = this.router.url.split('?')[0].split('/').pop();
		if(lastRoute != 'reauth-card' && this.utilServ.appPermission('rating') && this.utilServ.userId() && this.initServ.isRatingAllowed){
			this.getUnRatedBkngs();
		}
		// Set the campaign cookies
		this.campaignCookies();
	}

	ngOnInit(): void {
		this.ratingCookie(); // Rating cookie
		this.openCustomPopup();
	}
	/**
	 * Create the campaign cookies
	 * ses_id, campaign_id,contact_id, track_from, sequence_id
	 */
	private campaignCookies(): void {
		// Store query parameters
		if (this.actRoute.snapshot.queryParamMap) {
			let queryParamMap: any = this.actRoute.snapshot.queryParamMap;
			if (queryParamMap.params && (Object.keys(queryParamMap.params)).length > 0) {
				this.queryParams = queryParamMap.params
			}
		}
		// Create cookie
		if (this.queryParams && (Object.keys(this.queryParams)).length > 0) {
			for (let key of Object.keys(this.queryParams)) {
				this.cookieServ.createCookie(key, this.queryParams[key], 0);
			}
		}
	}
	/**
	 * Build page
	 * @param slug: page slug
	 * @param isDraft: draft
	 */
	public buildPage(slug: string, isDraft: boolean = false): any {
		// Query params
		let queryParams: any = { slug: slug, language: this.initServ.savedLng, mode: 'live', theme_slug: this.initServ.theme };
		if (isDraft) {
			queryParams['mode'] = 'preview';
		}
		// load (Api call) the page data & style if it is not cached, otherwise it return the cached data associated to slug provided.
		this.cacheServ.buildPageSection(queryParams, slug).then((res: any) => this.pageDataRes(res, slug));
	}
	private pageDataRes(res: any, slug: any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			let data: any = res.data;
			this.utilServ.redirectToUrlIfNotSame(data);
			if(data.column_sections && (data.column_sections).length > 0){
				data.added_sections = (data.added_sections).filter( (el: any) => !(data.column_sections).includes( el ) );
			}
			if(res.data && res.data.settings){
				let pageSett: any = res.data.settings;
				if(pageSett.redirect_status && pageSett.redirect_status == 'yes' && (pageSett.redirect_link_to == 'page' || pageSett.redirect_link_to == 'web') && pageSett.redirect_link_url){
					this.utilServ.redirectPageURL(pageSett); // Redirect page url
				} else {
					this.rcServ.setPageData = JSON.parse(JSON.stringify(data));
					// Theme popups
					// On reauth-card page not open the popup
					// eslint-disable-next-line max-depth
					if(slug != 'reauth-card'){
						this.rcServ.loadThemePopups(pageSett);
						// TODO: Lakvir move this function in lead branch
						this.initServ.setCurrPageIdInBody(res.data._id);
					}
					// Page title
					this.rcServ.setPageTitle(pageSett);
					// Add tracking codes added in page settings
					this.rcServ.addTrackingCodes(pageSett);
					// Page setting
					this.utilServ.setPageSett(res.data._id, pageSett);
				}
			}
			// Reset the page api hit count;
			this.rcServ.setPageApiHitCount = 0;
			/**
			 * When `isParentSlug` is true, means that current doesn't have its own customization, all the data & styling are coming from the its route parent,
			 * Therefore child inner-pages will rendered through app routes, and customization will be applied from the parent route slug.
			 * Inner pages are : "Dashboard", "Billing-info", "Drive", "Notifications", "Edit-profile" & "Gift-cards"
			 */
			if (this.isParentSlug) {
				this.appRouteStatus = true;
				this.isParentSlug = false;
			} else {
				this.createDynamicComponent();
			}
			this.initServ.firstPageData = null;
			this.initServ.firstPageSlug = null;
		} else {
			// Set the page data
			this.utilServ.setPageSett(0,null);
			this.utilServ.redirectBrokenUrl(slug);
		}
	}
	/**
	 * Remove the previous sections child
	 */
	private removeSections() {
		let contId: any = document.getElementById('page-container');
		let paras: any;
		if (contId) {
			paras = contId.getElementsByClassName('bk-element-container');
		}
		if (paras && paras.length > 0) {
			while (paras[0]) {
				paras[0].parentNode.removeChild(paras[0]);
			}
		}
	}
	/**
	 * Loop through all sections of the page
	 */
	private createDynamicComponent() {
		let i = 0;
		// Check sections length on the page
		if (this.rcServ.pageData && this.rcServ.pageData.added_sections && (this.rcServ.pageData.added_sections).length > 0) {
			this.vcRef.clear();
			this.removeSections();
			// Loop through all sections of the popup
			// for(let secId of this.rcServ.pageData.added_sections) {
			for (let index in this.rcServ.pageData.added_sections) {
				i = i + 1;
				this.createComponent(this.rcServ.pageData.added_sections[index], i);
			}
		}
	}
	/**
	 * Load components using "view reference"
	 * @param secId Id of section which we want to add on the page
	 * @param i index, so we can create unique id of wrapper for each section
	 */
	private createComponent(secId: string, i: number,) {
		// Get section slug so we can load the component
		let secSlug: string = this.rcServ.pageData.section_settings[secId].slug;
		if (secSlug) {
			this.rcServ.renderComponent(secId, secSlug, this.vcRef);
			let div = document.createElement('div');
			div.setAttribute('class', 'bk-element-container position-relative');
			div.setAttribute('id', 'component-' + i);
			div.appendChild(this.rcServ.componentRef.location.nativeElement);
			// When updating the cached page flow, html is not rendered to get the HTML elem having "this.containerId" id
			setTimeout(() => {
				let elem: any = document.getElementById('page-container');
				if (elem) {
					elem?.appendChild(div);
				}
			}, 0);
		}
	}
	/**
	 * Rating cookie
	 */
	private ratingCookie(): void {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.store && this.admnStngs.merchant_settings?.store?.domain_name) {
			this.cookieName = this.admnStngs.merchant_settings?.store?.domain_name + '_skipedRatings';
			let skipedRatingsStr = this.cookieServ.getCookie(this.cookieName);
			if (!skipedRatingsStr) {
				let skipedRatings: any = [];
				let value = JSON.stringify(skipedRatings);
				this.cookieServ.createCookie(this.cookieName, value, 365);
			}
		}
	}
	/**
	 * Get the un rated booking
	 */
	private getUnRatedBkngs(): void {
		this.apiServ.callApiWithPathVariables('GET', 'NotRatedBkng', [this.utilServ.userId()]).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.notRatedBkngRes(res));
	}
	private notRatedBkngRes(res: any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.unRatedBkngs = res.data;
			let skipedRatings = this.cookieServ.getCookie(this.cookieName);
			if(skipedRatings){
				let ratingArray = JSON.parse(skipedRatings);
				if (this.unRatedBkngs[0] && !(ratingArray).includes(this.unRatedBkngs[0]._id) && ((this.initServ.ratingAllowedFor).includes(this.unRatedBkngs[0]?.industry_id))) {
					let obj: any = {
						uid : this.unRatedBkngs[0].uid,
						provider_id : (this.unRatedBkngs[0].provider_info && (this.unRatedBkngs[0].provider_info).length> 0) ? this.unRatedBkngs[0].provider_info[0].id : 0,
						booking_id : this.unRatedBkngs[0]._id
					};
					this.popupServ.ratingPopup(this.unRatedBkngs[0], obj, true).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.ratingPopupRes(res));
				}
			}
		}
	}
	private ratingPopupRes(res: any): void {
		if(res){
			// Cookie set
			let skipedRatings : any = this.cookieServ.getCookie(this.cookieName);
			let ratingArray = JSON.parse(skipedRatings);
			if(this.unRatedBkngs && this.unRatedBkngs.length > 0){
				for(let booking of this.unRatedBkngs){
					if(!(ratingArray).includes(booking._id)){
						ratingArray.push(booking._id);
					}
				}
			}
			let value = JSON.stringify(ratingArray);
			if(encodeURIComponent(value).length > 4000){
				let newValues = ratingArray.splice(0, 100);
				value = JSON.stringify(newValues);
			}
			this.cookieServ.createCookie(this.cookieName, value, 365);
			if (res.type == 'submit') {
				this.submitRating(res);
			} else {
				this.getUnRatedBkngs();
			}
		}
	}
	private submitRating(res: any): void {
		if (this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.customers && this.admnStngs.merchant_settings?.customers?.share_to_third_party_sites && this.admnStngs.merchant_settings?.customers?.share_to_third_party_sites == 'yes') {
			if (this.admnStngs.merchant_settings?.customers?.share_review_for_service && (this.admnStngs.merchant_settings.customers.share_review_for_service).length > 0 && (this.admnStngs.merchant_settings.customers.share_review_for_service).includes(res.booking.occurrence)) {
				if (this.admnStngs.merchant_settings?.customers?.share_specific_review) {
					if (+res.rating.rating >= +this.admnStngs.merchant_settings?.customers?.specific_review) {
						//open share review popup
						this.popupServ.shareReviewPopup(res.booking, res.rating).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.shareReviewPopupRes(res));
					}
				} else {
					//open share review popup
					this.popupServ.shareReviewPopup(res.booking, res.rating).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.shareReviewPopupRes(res));
				}
			} else {
				this.getUnRatedBkngs();
			}
		} else {
			this.getUnRatedBkngs();
		}
	}
	private shareReviewPopupRes(res: any): void {
		if(res){
			this.getUnRatedBkngs();
		}
	}
	/**
	 * Open popup for merchant
	 */
	private openCustomPopup(): void {
		this.actRoute.queryParams.subscribe(params => {
			if (this.utilServ.adminPage && params.popup_id && +params.popup_id > 0) {
				let elems: any = document.getElementsByClassName('tjs-main');
				if (elems && elems.length > 0) {
					for (let elem of elems) {
						elem.classList.add('d-none');
					}
				}
				setTimeout(() => {
					this.rcServ.buildPopup(+params.popup_id, true);
				}, 0)
			}
		});
	}
	ngOnDestroy() {
		this._routeListener.unsubscribe();
	}
}
