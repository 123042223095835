import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';
// External library
import {  generateSessionHeaders, getSessionHeaders} from 'iron-crypto-pkg';
// Services
import { InitServ } from '../../Services/Initializer.service';
// Environments
import { environment as env } from '../../../environments/environment';
import { ALLOW_OLD_VERSION } from 'src/app/Constants';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(private initServ: InitServ){}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		let currentUser : any;
		const auth = request.headers.get('auth') ? request.headers.get('auth') : 'true';
		try{
			let userJson: any;
			try{
				userJson = localStorage.getItem("currentUser");
			}catch(err){ /* empty */ }
			currentUser = userJson !== null ? JSON.parse(userJson) : null;
		}
		catch{ /* empty */ }
		request = this.constructRequestHeaders(auth, request, currentUser);
		if(auth !== 'false'){
			return this.refreshHeaders(request, next);
		}else{
			return next.handle(request);
		}
	}

	/**
	 * Constructs request headers based on authentication status and current user data.
	 * If authentication is disabled, removes the 'auth' header.
	 * If a valid user token and IP are provided, sets the 'Authorization' header with the token and 'Ip' header with the IP.
	 * @param auth - Flag indicating whether authentication is enabled.
	 * @param request - Original HTTP request.
	 * @param currentUser - Current user data containing token and IP.
	 * @returns Cloned HTTP request with modified headers.
	 */
	private constructRequestHeaders(auth: any, request: HttpRequest<any>, currentUser: any){
		if(auth == 'false'){
			return request.clone({
				headers: request.headers.delete('auth')
			});
		} else if (currentUser?.token && currentUser?.Ip) {
			return request.clone({
				setHeaders: {
					Authorization: `Bearer ${currentUser.token}`,
					Ip: currentUser.Ip
				}
			});
		} else if(this.initServ?.ipAddress){
			return request.clone({
				setHeaders: {
					Ip: this.initServ.ipAddress
				}
			});
		}
		return request;
	}

	private refreshHeaders(request: any, next: any) {
		// By making use of the from operator of RxJS convert the promise to an observable
		return from(this.getSessionHeaders()).pipe(switchMap((headers: any) => this.updateHeadersAndRetry(request, next, headers)))
	}

	/**
	 * Updates request headers with the provided headers and retries the request.
	 * @param request - Original HTTP request.
	 * @param next - HTTP handler for processing the modified request.
	 * @param headers - New headers to be set in the request.
	 * @returns Observable of HTTP events for the retried request.
	 */
	private updateHeadersAndRetry(request: HttpRequest<any>, next: HttpHandler, headers: any): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: headers
		});
		return next.handle(request);
	}

	/**
	 * Generates the necessary headers for a session authentication request.
	 * Set header values for a session. It generates values for 'Period', 'Session-Token', and 'Auth-Session' headers and returns them as an object
	 */
	private async getSessionHeaders() {
		// Global variable for !temporarily commented from here to handle security failure callback. When the new security is not stable on production then it will be enabled. We remove this when every think working fine.
		if(ALLOW_OLD_VERSION){
			return await getSessionHeaders(env.arjun+env.bhishma+env.chanakya+env.drona, env.astonmartin+env.bentley);
		} else {
			let domainName: string = this.initServ.getDomainName();
			return await generateSessionHeaders(env.audi+env.buick+env.chevrolet+env.dodge, domainName, env.abhimanyu+env.bharata+env.chandra+env.devika);
		}
	}
}
