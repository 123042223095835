import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
// External lib
import { ToastrService } from 'ngx-toastr';
// Services
import { NgOnDestroy, RenderComponentServ, SectionServ, InitServ, UtilServ, LeadsServ } from '../../Services';
import { AuthServ } from '../../Core/_services';
// Constants
import { EMAIL_REG_EXP, PASSWORD_MIN_LENGTH, TEXT_REG_EXP, IS_DEV } from '../../Constants';
//Custom validator
import { CustomValidators } from '../../Global/GlobalDefault';
@Component({
	selector: 'bk-signup',
	templateUrl: './Signup.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [NgOnDestroy, CustomValidators]
})
export class SignupComponent implements OnInit {
	// Variables
	@Input() secId: string = '';
	socialKeys: any = this.initServ.appSocialKeys; // App social keys
	pageSett: any;
	// Section fields
	section: any = {
		title: null,
		sub_title: null,
		media: null,
		social: null,
		form: null
	}
	signupForm!: FormGroup; // signup form
	admnStngs: any;
	// eslint-disable-next-line max-params
	constructor(private frmBldr: FormBuilder, public rcServ: RenderComponentServ, public secServ: SectionServ, public initServ: InitServ, private customValidators: CustomValidators, private authServ: AuthServ, public utilServ: UtilServ, public toastr: ToastrService, private leadsServ: LeadsServ){
		this.admnStngs = this.initServ.appAdmnStngs; // Admin settings
		if(!this.utilServ.embedStatus && !this.initServ.theme){
			this.authServ.removeCurrentUser()
		}
	}

	ngOnInit(): void {
		// Embed status: true and theme not exist remove the current login user local storage
		if(this.utilServ.embedStatus && !this.initServ.theme){
			// this.authServ.removeCurrentUser();
			this.utilServ.id = null;
		}
		// Signup form build
		this.signupForm = this.frmBldr.group({
			first_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			last_name: [null, [Validators.required, Validators.pattern(TEXT_REG_EXP)]],
			email_id: [
			null,{
				validators: [Validators.required, Validators.pattern(EMAIL_REG_EXP)],
				asyncValidators: [this.customValidators.existingEmail()],
				updateOn: 'blur'
			}],
			password: ['', [Validators.required, Validators.minLength(PASSWORD_MIN_LENGTH)]],
			confirm_password: ['', [Validators.required]],
			customer_name:[''],
			ip: [this.initServ.ipAddress],
			user_agent: [navigator.userAgent]
		},{
			validators: [CustomValidators.matchPassword('password', 'confirm_password')]
		});
		// Build section data
		if(this.secId && this.rcServ.pageData){
			this.pageSett = this.rcServ.pageData.section_settings;
			this.secServ.setServData(this.pageSett, this.rcServ.pageData.content);
			this.section = this.secServ.buildSectionFields(this.secId, this.section, this.rcServ.pageData);
		}
	}
	// convenience getter for easy access to form fields
	get f(): { [key: string]: AbstractControl } {
		return this.signupForm.controls;
	}
	/**
	 * Submit the signup form
	 */
	public submitForm(): any {
		if(this.signupForm.controls['customer_name'].value != ''){
			if(IS_DEV){ console.log('There is an issue with customer signup.') }
			return false;
		}
		if(this.signupForm.valid){
			this.signupForm.controls['email_id'].setValue((this.signupForm.controls['email_id'].value).toLowerCase());
			let authKey: string = '';
			// Auth key domain name + email id
			if(this.admnStngs && this.admnStngs.merchant_settings && this.admnStngs.merchant_settings?.store && this.admnStngs.merchant_settings?.store?.domain_name){
				authKey = this.admnStngs.merchant_settings?.store?.domain_name + this.signupForm.controls['email_id'].value;
			}
			this.detailsFocusOut(1);
			this.authServ.signup(this.signupForm.value,authKey, '', true);
		} else{
			for(let i in this.signupForm.controls){
				this.signupForm.controls[i].markAsTouched();
			}
			this.toastr.error('Please fill the required fields marked in red.');
		}
	}
	/**
	 * Handles the focus out event for the details section.
	 * This function checks the validation status of the email_id form control.
	 * If the email is valid and meets all the specified criteria, it adds contact information to a lead.
	 */
	public detailsFocusOut(interval: number | null = null): void {
		if(!this.f['email_id']?.errors?.required && !this.f['email_id']?.errors?.pattern && !this.f['email_id']?.errors?.minlength && !this.f['email_id']?.errors?.matching && !this.f['email_id']?.errors?.emailExists){
			this.leadsServ.addContactToLead(this.signupForm.value, {type: 'sign_up', slug: 'sign-up'}, interval)
		}
	}
}
