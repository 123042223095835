import { Component, Input, OnInit, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from "rxjs/operators";
// External lib
import dayjs from 'dayjs';
// Services
import { NgOnDestroy, InitServ, ApiServ, LoaderServ, UtilServ } from '../../../../Services';
import { UserPopupServ } from '../../UserPopup.service';

@Component({
	selector: 'bk-profile-card',
	templateUrl: './ProfileCard.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ProfileCardComponent implements OnInit {
	// Variables
	@Input() type: string = 'dashboard';
	@Input() section:any = '';
	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	greeting: string = '';
	booking: any;
	loaderId: string = 'profile-card';

	constructor(@Self() private destroy: NgOnDestroy, private cDRef: ChangeDetectorRef, public initServ: InitServ, private apiServ: ApiServ, private loader: LoaderServ, public utilServ: UtilServ, private userPopupServ: UserPopupServ) {
		if(!initServ.apiLoadStatus['bkngCustData']){
			this.initServ.loadBkngCustData().then(() => {
				this.cDRef.detectChanges();
			});
		}
		this.initServ.loadServCatsAndFreqs().then(() => {
			this.cDRef.detectChanges();
		});
	}

	ngOnInit(): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		// Greeting
		this.greeting = this.greetingText();
		if(this.type == 'dashboard'){
			this.singleBooking();
		}
		// Get the user info
		this.initServ.isUserProfile.pipe(takeUntil(this.destroy)).subscribe((value) => {
			if(value){
				this.cDRef.detectChanges();
				if(this.type != 'dashboard'){
					this.loader.hide(this.loaderId);
				}
			}
		});
	}
	/**
	 * Greeting text
	 * @returns
	 */
	private greetingText(): string {
		const now = dayjs();
		const currentHour = now.hour();
		if (currentHour >= 12 && currentHour < 16) return "Good Afternoon!"
		else if (currentHour >= 16 && currentHour <= 24) return "Good Evening!"
		else return "Good Morning!";
	}
	/**
	 * Single upcoming booking
	 */
	private singleBooking(): void {
		let queryParams = {status: 'upcoming', limit: 1, page: 1};
		this.apiServ.callApiWithPathQueryVars('GET', 'Bookings', [this.utilServ.userId()], queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res));
	}
	/**
	 * On result callback method
	 * @param res: api
	 * API response handler
	 */
	private onResultCallback(res: any): void {
		if (this.apiServ.checkAPIRes(res) && res.data && (res.data).length > 0) {
			this.booking = res.data[0];
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}
	/**
	 * Logged user change password popup
	 */
	public changePwd(): void {
		this.userPopupServ.changePwdPopup();
	}
}
