/* eslint-disable complexity */
/* eslint-disable max-depth */
import { Component, Input, Output, OnInit, EventEmitter, ViewEncapsulation, Self, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
// Services
import { NgOnDestroy, InitServ, ApiServ, BkngFormServ, UtilServ, LoaderServ, SectionServ } from '../../../../Services';
import { BkngFormPopupServ } from '../../BkngFormPopup.service';
// Constant
import { MAP_ADDRESS_LINK } from '../../../../Constants';

@Component({
	selector: 'bk-zipcode',
	templateUrl: './Zipcode.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [NgOnDestroy]
})
export class ZipcodeComponent implements OnInit {
	// Variables
	@Input() bookingType: string = '';
	@Input() section: any;
	@Input() prefilledData: any;
	@Input() isQuoteEditable: boolean = true;
	@Input() settings: any;
	@Input() zipcodeForm!: FormGroup;
	@Input() pageSett: any;
	@Input() refreshLocComp: boolean = false;
	@Input() mlDesignId: string = 'bk_ml_loc_v1';
	@Input() isShortForm: boolean = false;
	@Output() locationFocusOut: EventEmitter<any> = new EventEmitter();
	@Output() merchantLocFocusOut: EventEmitter<any> = new EventEmitter();
	@Output() locationChange: EventEmitter<any> = new EventEmitter();

	admnStngs: any = this.initServ.appAdmnStngs; // App admin settings
	mapAddrLink: string = MAP_ADDRESS_LINK;
	loaderId: string = 'merchant-loc-loader';
	zipcode: any;
	merchantLocations: any;
	zipCodeStatus: boolean = false;
	noZipCodeStatus: boolean = false;
	findLocBtnText: string = this.initServ?.appStr?.text?.findLocations;
	mlTypeKeyword: any = '';
	btnTooltip: string = this.initServ?.appStr?.tTip?.bkngFormZipcode;
	selectedMerchantLoc: any;
	zipcodeDescStatus: boolean = false;
	shortFormData: any;


	constructor(private initServ: InitServ, private apiServ: ApiServ, @Self() private destroy: NgOnDestroy, private bkngFormServ: BkngFormServ, private cDRef: ChangeDetectorRef, public utilServ: UtilServ, private loader: LoaderServ, private bkngFormPopupServ: BkngFormPopupServ, private secServ: SectionServ) { }

	ngOnInit(): void {
		this.buildLoc();
		if(!this.isShortForm && this.section && this.section?.id && this.pageSett && this.pageSett[this.section?.id] && this.pageSett[this.section?.id]['design_id']){
			this.mlDesignId = this.pageSett[this.section?.id]['design_id'];
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes){
			for(let propName in changes) {
				let chng = changes[propName];
				if (!chng.firstChange) {
					let cur = JSON.stringify(chng.currentValue);
					let prev = JSON.stringify(chng.previousValue);
					if(cur != prev){
						this.buildLoc();
						break;
					}
				}
			}
		}
	}
	/**
	 * BUild the location
	 */
	private buildLoc(): void {
		// Short form data
		if(this.bookingType == 'add'){
			this.shortFormData = this.bkngFormServ.getShortFormData;
			if(this.zipcodeForm.controls['location_type'].value == 'ML'){
				(<FormGroup>this.zipcodeForm.controls['customer']).removeControl('address');
				(<FormGroup>this.zipcodeForm.controls['customer']).addControl('address', new FormControl(''));
				this.getMerchantLocations(true);
			} else {
				if(this.settings && this.settings?.form_data && this.settings?.form_data?.preferred_service_location && (this.settings?.form_data?.preferred_service_location == 'both' || this.settings?.form_data?.preferred_service_location == 'merchant_location')){
					this.getMerchantLocations();
				}
			}
		}
		if(this.section){
			this.zipcodeDescStatus = this.secServ.checkEleStatus(this.pageSett,this.section.zipcode_desc_id);
		}
		if((this.prefilledData) || (this.shortFormData)){
			if((this.prefilledData && this.prefilledData.location_type == 'ML') || (this.shortFormData && this.shortFormData.location_type == 'ML')){
				this.getMerchantLocations(true);
			} else{
				if(this.settings && this.settings?.form_data && this.settings?.form_data?.preferred_service_location && (this.settings?.form_data?.preferred_service_location == 'both' || this.settings?.form_data?.preferred_service_location == 'merchant_location')){
					this.getMerchantLocations();
				}
			}
		}
		// Automatic focusout when zipcode value is prefilled
		// Only work zipcode field auto field, like browser cache
		setTimeout(()=>{
			let zipcodeEl:any = document.getElementById('zipcode-value');
			if (zipcodeEl && zipcodeEl.value && this.zipcodeForm.controls['location_type'].value == 'SA' && !this.zipcodeForm.controls['location_id'].value && this.bookingType == 'add') {
				this.checkZipcodeAvail(zipcodeEl.value);
			}
			this.cDRef.detectChanges();
		},1000);
	}
	/**
	 * Get the merchant locations
	 */
	private getMerchantLocations(isFirst: boolean = false, changeByHtml:boolean = false): void {
		this.apiServ.setLoaderId(this.loaderId);
		this.loader.show(this.loaderId);
		// Query params
		let queryParams: any = {type: 'ML',form_id: this.zipcodeForm.controls['form_id'].value, industry_id: this.zipcodeForm.controls['industry_id'].value, all: 'true', s: this.mlTypeKeyword};
		let extraData: any = {isFirst: isFirst, changeByHtml: changeByHtml};
		this.apiServ.callApiWithQueryParams('GET', 'MerchantLocations', queryParams).pipe(takeUntil(this.destroy)).subscribe((res:any)=>this.onResultCallback(res, extraData));
	}
	/**
	 * Location type changes SA to ML and visa versa
	 * @param value SA & ML
	 */
	public locTypeChange(type: string): void {
		this.findLocBtnText = this.initServ?.appStr?.text?.findLocations;
		this.mlTypeKeyword = '';
		this.selectedMerchantLoc = null;
		this.setZipcodeFormValues(null);
		this.zipcodeForm.controls['zipcode'].markAsUntouched();
		this.zipCodeStatus = false;
		this.noZipCodeStatus = false;
		// this.merchantLocations = null;
		(<FormGroup>this.zipcodeForm.controls['customer']).removeControl('address');
		if(type == 'SA'){
			(<FormGroup>this.zipcodeForm.controls['customer']).addControl('address', new FormControl('', Validators.required));
		} else{
			// this.getMerchantLocations(false, true);
			this.checkTestDataFlushed('ML');
			if(this.merchantLocations && (this.merchantLocations).length == 1){
				let loc: any = this.merchantLocations[0];
				this.setMLVariables(loc);
				this.setZipcodeFormValues(loc);
				this.merchantLocFocusOut.emit(loc);
			}
			(<FormGroup>this.zipcodeForm.controls['customer']).addControl('address', new FormControl(''));
		}
		this.locationChange.emit();
		this.cDRef.detectChanges();
	}
	/**
	 * Check the test data is flushed
	 * @param type SA & ML
	 * @returns For SA case return true else removed merchant address
	 */
	private checkTestDataFlushed(type: string): any {
		let totalTestLoc = null;
		if(this.admnStngs.merchant_settings?.store?.test_data_flushed) {
			totalTestLoc = this.bkngFormServ.checkTestLocations(this.admnStngs.merchant_settings?.store?.test_data_flushed,this.settings.locations);
			if(this.admnStngs.merchant_settings?.store?.test_data_flushed == 'no' && this.settings && this.settings.locations){
				if(type == 'SA'){
					if((this.settings.locations).length == totalTestLoc){
						return true;
					}
					return false;
				} else {
					if(this.merchantLocations && this.merchantLocations.length > 0) {
						let index = (this.merchantLocations).findIndex((x: { test_data: string; }) => x.test_data == "no");
						if((this.settings.locations).length > totalTestLoc && index > 0){
							this.merchantLocations.splice(index, 1);
						}
					}
				}
			}
		}
	}
	/**
	 * Check the user enter zipcode availability on app
	 * @param event : Input event
	 * Type SA
	 */
	// eslint-disable-next-line complexity, max-lines-per-function
	public checkZipcodeAvail(event: Event): void {
		if(event.target){
			this.zipcode = (<HTMLInputElement>event.target).value;
		} else {
			this.zipcode = event;
		}
		let zipCode = this.zipcode;
		let selectedLocation = null;
		let testDataStatus = false;
		let testDataFlushed: boolean = this.checkTestDataFlushed('SA');
		if(testDataFlushed){
			zipCode = '10001';
			testDataStatus = true
		}
		let isWildcardChecked : boolean = false
		let isWildCardExcludeMatched : boolean = false;
		let firstwildCardreturnObj: boolean = false;
		if(zipCode){
			zipCode = zipCode.toLowerCase();
			if(this.settings.locations && (this.settings.locations).length > 0){
				if(this.admnStngs.merchant_settings?.location_settings && this.admnStngs.merchant_settings?.location_settings?.wildcard_zipcodes == 'yes'){
					for(let location of this.settings.locations){
						if(this.checkTestLocStatus(testDataStatus, location)){
							continue;
						}
						if(location.exclude_zipcodes && (location.exclude_zipcodes).length > 0){
							location.exclude_zipcodes = location.exclude_zipcodes.map((value: string) => value.toLowerCase());
							if((location.exclude_zipcodes).includes(zipCode)){
								this.noZipCodeStatus = true;
								isWildCardExcludeMatched = true
								isWildcardChecked = true
								break;
							}
						}
					}
					if (!isWildCardExcludeMatched){
						/* check first matched wildcard zipcode */
						for(let location of this.settings.locations){
							if(this.checkTestLocStatus(testDataStatus, location)){
								continue;
							}
							if(location.wildcard_zipcodes && (location.wildcard_zipcodes).length > 0){
								location.wildcard_zipcodes = location.wildcard_zipcodes.map((value: string) => value.toLowerCase());
								if((location.wildcard_zipcodes).some((zip: any) => zipCode.indexOf(zip) === 0) ){
									this.noZipCodeStatus = false;
									this.zipCodeStatus = false;
									selectedLocation = location;
									firstwildCardreturnObj = true;
									isWildcardChecked = true
									break;
								}
							}
						}
						if(!firstwildCardreturnObj){
							/* check any matched wildcard zipcode */
							for(let location of this.settings.locations){
								if(this.checkTestLocStatus(testDataStatus, location)){
									continue;
								}
								if(location.wildcard_zipcodes && (location.wildcard_zipcodes).length > 0){
									location.wildcard_zipcodes = location.wildcard_zipcodes.map((value: string) => value.toLowerCase());
									if((location.wildcard_zipcodes).some((zip: any) => zipCode.includes(zip)) ){
										this.noZipCodeStatus = false;
										this.zipCodeStatus = false;
										selectedLocation = location;
										isWildcardChecked = true
										break;
									}
								}
							}
						}
					}
				}

				if(!isWildcardChecked){
					/* check primary zipcodes */
					for(let location of this.settings.locations){
						if(!testDataStatus && location.location && location.location.test_data && location.location.test_data == 'yes'){
							continue;
						}
						if(location.primary_zipcodes && (location.primary_zipcodes).length > 0){
							location.primary_zipcodes = location.primary_zipcodes.map((value: string) => value.toLowerCase());
							if((location.primary_zipcodes).includes(zipCode)){
								this.noZipCodeStatus = false;
								this.zipCodeStatus = false;
								selectedLocation = location;
								break;
							} else {
								this.noZipCodeStatus = true;
							}
						} else {
							this.noZipCodeStatus = true;
						}
					}
				}
			} else {
				this.noZipCodeStatus = true;
			}
		}
		this.locationFocusOut.emit(selectedLocation);
		this.cDRef.detectChanges();
	}

	/* function to check test location */
	checkTestLocStatus(testDataStatus:any, location:any){
		return (!testDataStatus && location.location && location.location.test_data && location.location.test_data == 'yes') ? true : false;
	}

	/**
	 * Find merchant location
	 */
	public findMerchantLoc(): void {
		this.selectedMerchantLoc = null;
		this.zipcodeForm.patchValue({
			merchant_address_picture: null,
			merchant_location_name: null
		});
		this.merchantLocSelect(null, true);
	}
	/**
	 * Set the merchant location variables
	 * @param loc : Merchant location
	 */
	private setMLVariables(loc: any): void {
		this.findLocBtnText = this.initServ?.appStr?.text?.chooseAnotherLocation;
		this.selectedMerchantLoc = loc;
		// Reschedule
		if(this.bookingType != 'add' && loc.zipcode){
			this.mlTypeKeyword = loc ? loc.zipcode : null;
		}
	}
	/**
	 * Select merchant location, set the form values
	 * @param loc: merchant location
	 */
	public merchantLocSelect(location: any, isFindLoc: boolean = false): void {
		let loc: any = location;
		// ml zipcode based get the location
		if(isFindLoc && this.mlTypeKeyword){
			this.checkTestDataFlushed('ML');
			if(this.merchantLocations && (this.merchantLocations).length > 0){
				for(let mlLoc of this.merchantLocations){
					if(mlLoc.zipcode == this.mlTypeKeyword){
						loc = mlLoc;
						break;
					}
				}
			}
		}
		if(loc){
			this.setZipcodeFormValues(loc);
			this.setMLVariables(loc);
			this.merchantLocFocusOut.emit(loc);
		}
		this.cDRef.detectChanges();
	}
	/**
	 * Location picture popup
	 * @param locPicture : any
	 */
	public locPicturePopup(locPicture: any): void{
		this.bkngFormPopupServ.picturePreviewPopup(locPicture);
	}
	/**
	 * Merchant location popup
	 */
	public merchantLocationsPopup(): void {
		this.bkngFormPopupServ.merchantLocationsPopup(this.merchantLocations).pipe(takeUntil(this.destroy)).subscribe((res: any) => {
			if(res){
				// Success merchant location selected
				this.merchantLocSelect(res);
			}
		});
	}
	/**
	 * Set the form values
	 * @param loc: selected location otherwise null
	 */
	private setZipcodeFormValues(loc: any = null, isFirst:boolean = false): void {
		this.zipcodeForm.controls.address.patchValue({
			address: loc ? loc.merchant_location_address : null,
			city: loc ? loc.city : null,
			state: loc ? loc.state : null,
			zipcode: loc ? loc.zipcode : null,
			apt: loc ? loc.apt : null
		});
		this.zipcodeForm.patchValue({
			address_id: loc ? loc.form_location.id : null,
			base_location_id: loc ? loc.form_location.location_id : null,
			merchant_address_picture: loc ? loc.picture : null,
			merchant_location_name: loc ? loc.location_name : null,
			zipcode: loc ? loc.zipcode : null
		});
		if(!isFirst){
			this.zipcodeForm.controls['location_id'].setValue(loc ? loc.form_location.id : null)
		}
	}
	/**
	 * On result callback method
	 * @param res api response
	 * API response handler
	 */
	private onResultCallback(res:any, extraData: any): void {
		if(this.apiServ.checkAPIRes(res) && res.data){
			this.merchantLocations = res.data;
			if(extraData){
				if(extraData.isFirst){
					if(this.merchantLocations && (this.merchantLocations).length > 0){
						for(let loc of this.merchantLocations){
							if(loc.form_location.id == this.zipcodeForm.controls['location_id'].value){
								this.setMLVariables(loc);
							}
						}
					}
				}
				// If only form settings merchant location and locations array lengh 1, select the location
				if(this.settings && this.settings?.form_data && this.settings?.form_data?.preferred_service_location && (this.settings?.form_data?.preferred_service_location == 'merchant_location')){
					if(this.merchantLocations && (this.merchantLocations).length == 1){
						this.locTypeChange('ML');
					}
				}
			}
		} else {
			this.merchantLocations = null;
		}
		this.loader.hide(this.loaderId);
		this.cDRef.detectChanges();
	}
	/**
	 * Refresh the component by parent
	 */
	public refresh(): void{
		this.cDRef.detectChanges();
	}
}
