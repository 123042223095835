/* eslint-disable no-prototype-builtins */
/* eslint-disable max-depth */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Route, Router } from '@angular/router';
// External lib
import { sha512Hash, generateSessionHeaders, getSessionHeaders } from 'iron-crypto-pkg';
// Environments
import { environment as env } from 'src/environments/environment';
// Constants
import { SELECTED_MASK, MASKS_OBJ, STR, IMG, DEFAULT_LANG_CODE, PREMIUM_PLANS, ADV_FEATURES, IS_MOBILE, IS_DEV, AUTH_KEY, GROWING_PLANS, GROWING_FEATURES, ROUTE_URLS, ALLOW_OLD_VERSION } from '../Constants';
// Api service
import { ApiServ, APIURL, CookieServ } from './index';
import { LoaderServ } from './Loader.service';

@Injectable({
	providedIn: 'root'
})
export class InitServ {
	// Readonly variables
	readonly appStr: any = STR;
	readonly img: any = IMG;
	readonly imgBase: string = this.APIURL.imgBase;
	readonly baseUrl: string = this.APIURL.baseUrl;
	readonly apiUrl: string = this.APIURL.apiUrl;
	readonly tinymceAPIKey: any = this.APIURL.tinymceAPIKey;
	readonly fileCdn: any = this.APIURL.fileCdn;
	privateCdn: any = this.APIURL.privateCdn;
	readonly routeUrls: any = ROUTE_URLS;
	// Private variables
	private destroy = new Subject<void>();
	private _ipAddress: any;
	private _appData: any;
	private _appAdmnStngs: any;
	private _appBookingSpots: any;
	public _userInfo: any;
	private _bkngCustData: any;
	private _summaryData: any;
	private _socialKeys: any;
	private _languages: any;
	private _headerFooter: any;
	private _themePopups: any;

	allServCats: any[] | null = null;
	allFreqs: any[] | null = null;
	// Public variables
	_siteData: any;
	paymentGateway: string = 'stripe';
	allowBillingAddr: boolean = false;
	selectedMask: any = SELECTED_MASK;
	defaultLangCode: string = DEFAULT_LANG_CODE;
	defaultProvLang: string = DEFAULT_LANG_CODE;
	savedLng: string = DEFAULT_LANG_CODE;
	serviceFeeLabel: string = '';
	callingCode: string = '';
	theme: string | null = '';
	lngCookieName: string = 'null';
	locationsStatus: boolean = true;
	appRoutes: string[] = [];
	selectedLang: any;
	activeTheme: string = 'simple';
	reRender: boolean = true;
	apiLoadStatus: any = {
		themePopups: false,
		bkngCustData: false,
		servsAndFreqs: false
	};
	public isRTLChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public isUserProfile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public userRefBal: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	public isLangSet: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
	public setTranslation: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
	public setLogoData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	// Trial features
	public trialFeatures: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	// Getter function
	// @returns app data
	get appData(): any { return this._appData; }
	// @returns app admin settings
	get appAdmnStngs(): any { return this._appAdmnStngs; }
	// @returns app languages
	get appLanguages(): any { return this._languages; }
	// @returns app booking spots
	get appBookingSpots(): Array<[]> { return this._appBookingSpots; }
	// @returns ip address
	get ipAddress(): string { return this._ipAddress; }
	// @returns loggedIn user information
	get userInfo(): any { return this._userInfo; }
	// @returns Booking customization
	get bkngCustData() { return this._bkngCustData; }
	get bkngSummData() { return this._summaryData; }
	// @returns App social keys
	get appSocialKeys(): any { return this._socialKeys; }
	// @returns header footer data
	get headerFooter(): any { return this._headerFooter; }
	// @returns theme builder popups
	get themePopupsData(): any { return this._themePopups; }
	// Get the site data
	get siteData(): any { return this._siteData }
	public appDynamicRoutes: any = {};
	statusType: string = 'status';
	firstPageData: any = null;
	firstPageSlug: any = null;
	firstPageLang: any = null;
	setProvLangStatus: boolean = false;
	threeDSecure: boolean = false;
	isRatingAllowed: boolean = true;
	ratingAllowedFor: Array<any> = [];
	storeDateFormat: string = "MM/DD/YYYY";

	// user pages urls with new slug
	userPagesRoutes:any = {
		'edit-personal-details': 'profile/edit',
		'info-and-billing':'info',
		'my-drive': 'drive'
	};

	defaultUserPagesRoutes: {[key:string]: string} = {
		'invoice': 'invoice',
		'invoice-payment': 'invoice-payment',
		'invoice-thankyou': 'invoice-thankyou',
		'dashboard': 'dashboard',
		'gift-cards': 'gift-cards',
		'notifications': 'notifications',
		'profile/edit': 'profile/edit',
		'edit-personal-details': 'profile/edit',
		'info-and-billing': 'info',
		'info': 'info',
		'drive': 'drive',
		'my-drive': 'drive',
	}

	// eslint-disable-next-line max-params
	constructor(private apiServ: ApiServ, private APIURL: APIURL, private cookieServ: CookieServ, private router: Router, private injector: Injector, private loader: LoaderServ) {
		this.statusType = IS_MOBILE ? 'mobile_status' : 'status';
		const url = new URL(window.location.href);
		this.lngCookieName = this.APIURL.domainName + '_language';
		// Get the theme slug from URL query params
		if (url.searchParams.get('theme')) {
			this.theme = url.searchParams.get('theme');
		}
	}
	/**
	 * Site data
	 * @returns Api Res
	 */
	public appSiteData(): Observable<any> {
		return this.apiServ.callApiWithQueryParams('GET', 'SiteData', { theme_slug: this.theme }).toPromise().then((res: any) => { this.onResultCallback(res, 'appSiteData') });
	}
	/**
	 * App load
	 * @returns Api Res
	 */
	public appload(): Observable<any> {
		this._appData = null;
		return this.apiServ.callApi('GET', 'AppLoadNew').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => this.onResultCallback(res, 'load'));
	}
	/**
	 * Admin settings
	 * @returns Api Res
	 */
	public admnStngs(): any {
		this._appAdmnStngs = null;
		return this.apiServ.callApi('GET', 'AdmnStngs').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'admin') });
	}
	/**
	 * Method `currentRouteSlug` returns the current route slug by extracting it from the window
	 * location pathname and performing some string manipulation.
	 * @returns a string value, which is the current route slug of the page.
	 */
	public currentRouteSlug(urlSlug:any = ''): string {
		// let slug: any = window.location.pathname;
		let slug: any = urlSlug;
		slug = slug.replace('/worker/', '');
		if (!slug || slug == '' || slug == '/') {
			slug = 'home';
		}

		slug = slug.startsWith('/') ? slug.substring(1) : slug;

		// slug for customer-interior pages
		if(Object.values(this.userPagesRoutes).includes(slug)){
			slug = Object.keys(this.userPagesRoutes).find((key:string) => this.userPagesRoutes[key] == slug) ?? slug;
		}

		return slug;
	}

	/**
	 * Method ***"changeCurrentRouteSlug"*** takes a slug as input and returns the corresponding route URL
	 * from a list of route URLs.
	 * @param {string} slug - A string representing the current route slug.
	 * @returns the value of `this.routeUrls[slug]` if a matching route is found. If no matching route is
	 * found, it returns `null`.
	 */
	public changeCurrentRouteSlug(slug: string): string | null {
		if(slug){
			slug = `${slug.split('/')[0]}/`;
			return this.routeUrls[slug] ?? null;
		}
		return null;
	}

	/**
	 * Limited Data
	 * @returns Api Res
	*/
	public pageData(): any {
		// get the current route slug of the current page.
		let slug: any = this.currentRouteSlug(this.router.url);
		//
		if (!slug.includes('reschedule-booking')) {
			let lang: any = this.cookieServ.getCookie(this.lngCookieName);
			// this is added for child inner pages ie. for 'appointments/**' and 'invoices/**'
			slug = this.changeCurrentRouteSlug(slug) ?? slug;
			// this.firstPageLang = lang ? lang : null;
			let queryParams: any = {slug:slug, language:(lang) ? lang : '', mode: 'live', theme_slug: ''};
			let params: any = new URLSearchParams(window.location.search);
			if (params && params.has('theme')) {
				queryParams['theme_slug'] = params.get('theme');
				queryParams['mode'] = 'draft';
			}
			return this.apiServ.callApiWithPathQueryVars('GET', 'PageData', [0], queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'pageData', slug) });
		} else {
			return null;
		}
	}
	/**
	 * App languages
	 * @returns Api Res
	 */
	public loadLanguages(): Observable<any> {
		this._languages = null;
		return this.apiServ.callApi('GET', 'Languages').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this._languages = res.data });
	}
	/**
	 * Booking customization data, handle by theme builder
	 * @param isEmpty
	 * @param code
	 * @returns Api Res
	 */
	public async loadBkngCustData(): Promise<any> {
		// await this.appLngCode();
		this._bkngCustData = null;
		this._summaryData = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng, mode: 'live' };
		if (this.theme) {
			queryParams['mode'] = 'preview';
		}
		return this.apiServ.callApiWithQueryParams('GET', 'BkngSummayCustomization', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'bkng-summary-custm') });
	}
	/**
	 * Site popups, handle by theme builder
	 * @returns
	 */
	public async loadThemePoups(): Promise<any> {
		this._themePopups = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng };
		return this.apiServ.callApiWithQueryParams('GET', 'SitePopups', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'popups') });
	}
	/**
	 * App header footer
	 * @returns
	 */
	public async loadHeaderFooter(): Promise<any> {
		// await this.appLngCode();
		this._headerFooter = null;
		let queryParams: any = { theme_slug: this.theme, language: this.savedLng, mode: 'live' };
		if (this.theme) {
			queryParams['mode'] = 'preview';
		}
		return this.apiServ.callApiWithQueryParams('GET', 'HeaderFooter', queryParams).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => {
			this._headerFooter = res.data;
			this.setLogoData.next(true);
		});
	}
	/**
	 * App all booking spots
	 * @returns
	 */
	public bookingSpots(): Observable<any> {
		this._appBookingSpots = null;
		return this.apiServ.callApi('GET', 'AllBookingSpots').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this._appBookingSpots = res.data; });
	}
	/**
	 * IP address for app
	 * @returns
	 */
	public appIpAddress(): any {
		this._ipAddress = null;
		return this.apiServ.callApi('GET', 'IpInfo', null, { auth: 'false' }, true).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this._ipAddress = res['ip']; });
	}
	/**
	 * App social keys
	 * @returns
	 */
	public loadSocialKeys(): Observable<any> {
		return this.apiServ.callApi('GET', 'Addons').pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'keys') });
	}
	/**
	 * App loggedIn user all information
	 * @param id User Id
	 * @returns
	 */
	public loggedInUser(id: any): Observable<any> {
		this._userInfo = null;
		return this.apiServ.callApiWithPathVariables('GET', 'Customer', [id]).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'customer') });
	}
	/**
	 * User referral balance
	 * @param id: user id
	 * @returns
	 */
	public userReferralsBal(id: any): Observable<any> {
		// this._userRefBal = null;
		return this.apiServ.callApiWithPathQueryVars('GET', 'ReferralsBalance', [id]).pipe(takeUntil(this.destroy)).toPromise().then((res: any) => { this.onResultCallback(res, 'ReferralsBal') });
	}
	/**
	 * Change app routing
	 */
	private changeAppRouting(): void {
		let routerConfig: any = this.router.config;
		routerConfig[23].children = this.replaceRoute(routerConfig[23].children);
		routerConfig[24].children = this.replaceRoute(routerConfig[24].children);
		routerConfig = this.replaceRoute(routerConfig);
		routerConfig = this.generateNewRoutes(routerConfig);
		this.router.resetConfig(routerConfig);
	}

	/**
	 * Replace the route
	 * @param children: Old child
	 * @returns children's
	 */
	private replaceRoute(children: any): void {
		if(children && children.length > 0){
			for(let i in children){
				let child = children[i];
				if(this.appDynamicRoutes[child.path]){
					child.path = this.appDynamicRoutes[child.path];
				}
			}
		}
		return children;
	}

	/**
	 * Add embed form routes in the system if there URL is updated
	 * @param routerConfig
	 * @returns Updated routerConfig with new routes.
	 */
	private generateNewRoutes(routerConfig: any): any {
		if(this.appDynamicRoutes.booknow != 'booknow'){
			let booknowRoute: any = { path: 'booknow', loadChildren: () => import('../AddBookings/AddBookings.module').then(m => m.AddBookingsModule)}
			if(routerConfig && routerConfig[23] && routerConfig[23].children){
				routerConfig[23].children.push(booknowRoute);
			}
		}
		if(this.appDynamicRoutes['contact-us'] != 'contact-us'){
			let leadFormRoute: any = { path: 'contact-us', loadChildren: () => import('../LeadForm/LeadForm.module').then(m => m.LeadFormModule), runGuardsAndResolvers: 'always'}
			if(routerConfig && routerConfig[24] && routerConfig[24].children){
				routerConfig[24].children.push(leadFormRoute);
			}
		}
		return routerConfig;
	}

	/**
	 * Set the admin setting global variables values
	 * Phone number masking, language code, service fee label and calling code
	 */
	private storeAdmnStngGlobalVar(): void {
		if (this._appAdmnStngs && this._appAdmnStngs.merchant_settings) {
			// Set the 3DS global variable
			this.setThreeDSecure();
			// Set store date format
			this.setDateFormat();
			// Active theme
			if (this.theme) {
				this.activeTheme = this.theme;
			} else if (this._appAdmnStngs?.active_theme) {
				this.activeTheme = this._appAdmnStngs.active_theme;
			}
			// Set the app language cookie
			this.setAppLang();
			this.setRatingAllowedStatus();
			// Payment gateway
			if (this._appAdmnStngs.merchant_settings?.payment_method) {
				let isSquare = this._appAdmnStngs.merchant_settings?.payment_method?.enable_square;
				let isPaypal = this._appAdmnStngs.merchant_settings?.payment_method?.enable_paypal_braintree;
				let isAuthDotNet = this._appAdmnStngs.merchant_settings?.payment_method?.enable_authorizedotnet;
				if (isSquare == 'yes') {
					this.paymentGateway = 'square';
				} else if (isPaypal == 'yes') {
					this.paymentGateway = 'paypal';
				} else if (isAuthDotNet) {
					this.paymentGateway = 'authorizedotnet';
				} else {
					this.paymentGateway = 'stripe';
				}
				// No payment method enable, default stripe enable
				if ((!isSquare || isSquare == 'no') && (!isPaypal || isPaypal == 'no') && (!isAuthDotNet || isAuthDotNet == 'no')) {
					this.paymentGateway = 'stripe';
				}
				// Billing Address
				if(this.paymentGateway == 'authorizedotnet'){
					this.allowBillingAddr = true;
				} else {
					this.allowBillingAddr = (this._appAdmnStngs.merchant_settings?.payment_method?.add_billing_address == 'yes') ? true : false;
				}
			}
			if (this._appAdmnStngs.merchant_settings?.store) {
				// Phone number masking
				if (this._appAdmnStngs.merchant_settings?.store?.phone_number_format && MASKS_OBJ.hasOwnProperty(this._appAdmnStngs.merchant_settings?.store?.phone_number_format)) {
					this.selectedMask = MASKS_OBJ[this._appAdmnStngs.merchant_settings?.store?.phone_number_format];
				}
				// Calling code
				this.callingCode = '';
				if (((this._appAdmnStngs.merchant_settings?.store).hasOwnProperty('show_calling_code') && this._appAdmnStngs.merchant_settings?.store?.show_calling_code && this._appAdmnStngs.merchant_settings?.store?.show_calling_code == 'yes') && ((this._appAdmnStngs.merchant_settings.store).hasOwnProperty('country_calling_code') && this._appAdmnStngs.merchant_settings?.store?.country_calling_code)) {
					this.callingCode = this._appAdmnStngs.merchant_settings?.store?.country_calling_code;
				}
			}
			// Service fee label
			if (this._appAdmnStngs.merchant_settings?.general && this._appAdmnStngs.merchant_settings?.general?.customize_service_fee_label && this._appAdmnStngs.merchant_settings?.general?.customize_service_fee_label == 'yes' && this._appAdmnStngs.merchant_settings?.general?.service_fee_label) {
				this.serviceFeeLabel = this._appAdmnStngs.merchant_settings?.general?.service_fee_label;
			}
			// App custom routes
			this.getAppRoutes();
		}
	}
	private setRatingAllowedStatus(): void {
		if(this._appAdmnStngs.merchant_settings?.customers){
			if(this._appAdmnStngs.merchant_settings?.customers?.allow_customer_rating && this._appAdmnStngs.merchant_settings?.customers?.allow_customer_rating == 'no'){
				this.isRatingAllowed = false;
			}else{
				if(this._appAdmnStngs.merchant_settings?.customers?.allow_customer_rating_for && (this._appAdmnStngs.merchant_settings.customers.allow_customer_rating_for).length > 0){
					this.ratingAllowedFor = this._appAdmnStngs.merchant_settings?.customers?.allow_customer_rating_for;
				}
			}
		}
	}
	/**
	 * Set the three D Secure global variable
	 */
	private setThreeDSecure(): void {
		if (this._appAdmnStngs && this._appAdmnStngs?.merchant_settings && this._appAdmnStngs?.merchant_settings?.payment_method && this._appAdmnStngs?.merchant_settings?.payment_method?.enable_3ds && this._appAdmnStngs?.merchant_settings?.payment_method?.enable_3ds == 'yes') {
			this.threeDSecure = true;
		}
	}
	/**
	 * Set the app language and cookie
	 */
	async setAppLang() {
		if (this._appAdmnStngs.merchant_settings?.language) {
			// Default language code
			// if(this._userInfo && this._userInfo.preferred_language && (this._userInfo.preferred_language).length > 0){
			// 	this.defaultLangCode = this._userInfo.preferred_language;
			// }else{
				if(this._appAdmnStngs.merchant_settings?.language?.customer_default_lang){
					this.defaultLangCode = this._appAdmnStngs.merchant_settings?.language?.customer_default_lang;
				}
			// }
			// Default provider language
			if (this._appAdmnStngs.merchant_settings?.language?.provider_default_lang) {
				this.defaultProvLang = this._appAdmnStngs.merchant_settings?.language?.provider_default_lang;
			}
		}
		let isPlanPermission: boolean = true;
		let isAllowOneLang: boolean = false;
		if (this._appAdmnStngs && this._appAdmnStngs.package_id) {
			isPlanPermission = await this.appPlansPermission('translation');
			if (!isPlanPermission) { isAllowOneLang = true; }
		}
		if (isPlanPermission || isAllowOneLang) {
			if (isAllowOneLang) {
				this.savedLng = this.defaultProvLang;
				this.cookieServ.createCookie(this.lngCookieName, '', 365);
				this.setProvLangStatus = true;
			} else{
				let savedLng = this.cookieServ.getCookie(this.lngCookieName);
				if(savedLng){
					this.savedLng = savedLng;
				} else {
					this.savedLng = this.defaultLangCode;
					this.cookieServ.createCookie(this.lngCookieName, this.savedLng, 365);
				}
			}
		}
		// if(this.firstPageLang != this.savedLng){
		// 	this.firstPageData = null;
		// 	this.firstPageSlug = null;
		// }
		this.isLangSet.next(true);
		this.loadCssFiles(this.savedLng ? this.savedLng : 'en');
	}
	/**
	* Check industry status for customer.
	* Check "customer_status" value
	*/
	private indusStatusForCust(industry: any): boolean {
		if (industry && industry.customer_status && industry.customer_status == 2) {
			return false;
		}
		return true;
	}
	/**
	* Check the location type is no_location, name and zipcode
	* If any one location name and zipcode base under the industries and forms.
	* Set the locationsStatus variable val;
	*/
	public checkLocationsStatus() {
		if (this._appData && this._appData.industries && (this._appData.industries).length > 0) {
			// for(let industry of this._appData.industries){
			for (let i in this._appData.industries) {
				let industry = this._appData.industries[i];
				if (industry.status == 1 && this.indusStatusForCust(industry) && industry.forms && (industry.forms).length > 0) {
					let locstatus = (industry.forms).some((form: { form_status: any; location_type: string; }) => {
						if (form.form_status && form.location_type != 'no_location') {
							return true;
						}
						return false;
					});
					if (locstatus) {
						this.locationsStatus = true;
						break;
					} else {
						this.locationsStatus = false;
					}
				}
			}
		}
	}
	/**
	 * On result callback method
	 * @param res: api
	 * @param type: load/admin/languages/customer/keys
	 * Api Res handler
	 */
	private async onResultCallback(res: any, type: string, slug: any = '') {
		switch (type) {
			case 'pageData':
				if (this.apiServ.checkAPIRes(res) && res.data && !this.setProvLangStatus) {
					this.firstPageData = res;
					this.firstPageSlug = slug;
				}
				break;
			case 'appSiteData':
				if (this.apiServ.checkAPIRes(res) && res.data) {
					this._siteData = res.data;
					if (res.data.page_urls) {
						this.appDynamicRoutes = res.data.page_urls;
						// Remove slash from routes first index
						if (this.appDynamicRoutes) {
							let routesKeys = Object.keys(this.appDynamicRoutes);
							if (routesKeys && routesKeys.length > 0) {
								for (let key of routesKeys) {
									let route: any = this.appDynamicRoutes[key];
									if (route) {
										let url = (route.charAt(0) == '/') ? (route.substr(1)) : route;
										this.appDynamicRoutes[key] = url;
									}
								}
							}
						}
						// Add the user pages routes for the accounts that does not exist the page urls.
						this.addUserPages();
						// TODO: Anupam, test it
						let userRoutes:any = await this.appendUserRoutes(this.userPagesRoutes).then((route) => route);
						this.appDynamicRoutes = { ...this.appDynamicRoutes, ...userRoutes};
						this.changeAppRouting();
					}
					// App custom routes
					this.getAppRoutes();
				}
				break;
			case 'load':
				this._appData = res.data;
				this.checkLocationsStatus();
				break;
			case 'admin':
				this._appAdmnStngs = res.data;
				this.storeAdmnStngGlobalVar();
				break;
			case 'customer':
				if (this.apiServ.checkAPIRes(res)) {
					this._userInfo = res.data;
					let localItem: any = localStorage.getItem('currentUser');
					let currentUser: any = JSON.parse(localItem);
					// Set the user local storage
					if (currentUser && this._userInfo) {
						currentUser['first_name'] = this._userInfo?.first_name ? this._userInfo?.first_name : '';
						currentUser['last_name'] = this._userInfo?.last_name ? this._userInfo?.last_name : '';
						currentUser['photo_url'] = this._userInfo?.photo_url ? this._userInfo?.photo_url : '';
						try {
							localStorage.setItem('currentUser', JSON.stringify(currentUser));
							// eslint-disable-next-line no-empty
						} catch (err) { }
					}
					this.isUserProfile.next(true);
					if (currentUser) {
						// Referral balance
						this.userReferralsBal(res.data._id);
					}
					// this.setAppLang();
				}
				break;
			case 'ReferralsBal':
				if (this.apiServ.checkAPIRes(res)) {
					this.userRefBal.next(res.data);
				}
				break;
			case 'keys':
				this._socialKeys = null;
				// App social keys
				if (this.apiServ.checkAPIRes(res) && res.data && (res.data).length > 0) {
					let addons: any = res.data;
					if (addons && addons.length > 0) {
						for (let addon of addons) {
							if (this._socialKeys == null) {
								this._socialKeys = {}
							}
							if (addon.addon_type == 'facebook') {
								if ((addon.status && addon.status == 1) && (addon.details && addon.details.app_id)) {
									this._socialKeys['Facebook'] = addon.details.app_id;
								}
							} else if (addon.addon_type == 'google') {
								if ((addon.status && addon.status == 1) && (addon.details && addon.details.client_id)) {
									this._socialKeys['Google'] = addon.details.client_id;
								}
							}
						}
					}
				}
				break;
			case 'popups':
				if (this.apiServ.checkAPIRes(res)) {
					if (res.data && (res.data).length > 0) {
						let resData: any = res.data;
						let popups: any = {};
						// for(let popup of resData){
						for (let i in resData) {
							let popup = resData[i];
							popups[popup?._id] = popup;
						}
						this._themePopups = popups;
					}
					this.apiLoadStatus['themePopups'] = true;
				}
				break;
			case 'bkng-summary-custm':
				if (this.apiServ.checkAPIRes(res) && res.data) {
					this._summaryData = res.data;
					let custData: any = {};
					if (this._summaryData && Object.keys(this._summaryData).length > 0) {
						for (let key in this._summaryData) {
							if (this._summaryData[key]?.sections && Object.keys(this._summaryData[key]?.sections).length > 0) {
								let sections: any = this._summaryData[key]?.sections;
								custData[key] = {};
								let parentKey = Object.keys(sections);
								if (parentKey && parentKey.length > 0) {
									let secId = parentKey[0];
									for (let sec in sections[secId]) {
										let keyId = sec + '_id';
										custData[key][keyId] = sections[secId][sec];
										custData[key][sec] = this.getText(sections[secId][sec], this._summaryData[key]?.section_settings, this._summaryData[key]?.content)
									}
								}

							}
						}
					}
					this._bkngCustData = custData;
					this.apiLoadStatus['bkngCustData'] = true;
				}
				break
		}
	}
	/**
	 * Append the new slug based urls for the inner pages that is added into the appDynamic routes object.
	 * @param userRoutes slug based urls for the inner pages
	 * @returns
	 */
	private appendUserRoutes(userRoutes: any): Promise<any>{
		let obj:any = {}
		return new Promise<any>((resolve) => {
			if(Object.keys(userRoutes)?.length > 0){
				for(let route in userRoutes){
					obj[userRoutes[route]] = this.appDynamicRoutes[route]
				}
			}
			resolve(obj);
		});
	}

	/**
	 * Add the user pages (inner pages) routes when appDynamicRoutes does not contains in it.
	 */
	private addUserPages(): void {
		if (Object.keys(this.defaultUserPagesRoutes)?.length > 0) {
			for (let route in this.defaultUserPagesRoutes) {
				// if routes does not contain in appDynamicRoutes then add the route in it.
				if (!this.appDynamicRoutes[route]) {
					this.appDynamicRoutes[route] = this.defaultUserPagesRoutes[route];
				}
			}
		}
	}

	/**
	 * Get the customization text
	 * @param id: section id
	 * @param settings: settings
	 * @param content: content
	 * @returns
	 */
	private getText(id: string, settings: any, content: any): string {
		if (settings && settings[id] && settings[id][this.statusType]) {
			if (content[id]) {
				return content[id];
			}
			return '';
		}
		return 'ele_hide';
	}
	/**
	 * Get the app custom routes array
	 */
	private getAppRoutes(): void {
		const topLevelRoutes = this.router.config.slice(0, this.router.config.findIndex((route) => route.path === '**') ?? this.router.config.length - 1);
		if (topLevelRoutes && topLevelRoutes.length > 0) {
			for (const i of topLevelRoutes) {
				this.getPaths(i);
			}
		}
	}
	/**
	 * Get the app custom path like /login,/dashboard etc
	 * @param route Route
	 * @param parent parent route
	 * @returns
	 */
	private getPaths(route: Route, parent: string = ''): void {
		if (route.redirectTo) {
			return;
		}
		if (route.children && (route.children).length > 0) {
			route.children.forEach(i => {
				this.getPaths(i, parent + route.path);
			});
		} else if (route.loadChildren) {
			(<any>this.router).configLoader.load(this.injector, route).subscribe((i: any) => {
				i.routes.forEach((j:any) => {
					this.getPaths(j, parent + route.path)
				});
			});
		} else if (route.path != null) {
			if (route.path !== '') {
				this.appRoutes.push(parent ? `/${parent}/${route.path}` : `/${route.path}`);
			} else {
				if(parent){
					this.appRoutes.push(`/${parent}`);
				}
			}
		}
	}
	private getFlag(langCode: string):string {
		if(this.appLanguages && (this.appLanguages).length > 0){
			for(let lang of this.appLanguages){
				if(lang.code == langCode){
					return lang.flag;
				}
			}
		}
		return langCode+'.png'
	}
	/**
	 * Load a css file based on language
	 * @param langCode
	 */
	private loadCssFiles(langCode: string): void {
		let elem: any = document.getElementsByClassName('custom-translation-style');
		while (elem.length > 0) {
			elem[0].parentNode.removeChild(elem[0]);
		}
		if (langCode) {
			let rtlLangs: Array<string> = ['ar', 'ha', 'he', 'ku', 'fa', 'ur'];
			this.selectedLang = {
				code: langCode,
				rtl: rtlLangs.includes(langCode),
				flag: this.getFlag(langCode)
			};

			// Add.remove class on body tag.
			let elem: any = document.getElementsByTagName("html");
			if (this.selectedLang.rtl || IS_DEV) {
				this.loader.show()
				this.isRTLChange.next(this.selectedLang.rtl);
				let path = this.selectedLang.rtl ? '/rtl/' : '/';
				let themePath: any;
				if (IS_DEV) {
					themePath = '/assets/css' + path + this.activeTheme;
				} else {
					themePath = this.APIURL.bkcdn + '/assets/css/' + this.APIURL.themeVersion + path + this.activeTheme;
				}
				let rendNo = Math.floor(Math.random() * 1000000000);
				let link = document.createElement('link');
				link.id = 'theme-css';
				link.rel = 'stylesheet';
				link.type = 'text/css';
				link.href = themePath + '-theme.css?v=' + rendNo;
				let head = document.head || document.getElementsByTagName('head')[0];
				let existingStyleSheet: any = document.getElementById('theme-css');
				if (existingStyleSheet) {
					existingStyleSheet.parentNode.insertBefore(link, existingStyleSheet.nextSibling);
					existingStyleSheet.remove();
				} else {
					head.prepend(link);
				}
				document.body.classList.add('tjs-rtl-layout');
				if (elem && elem.length > 0) {
					elem[0].setAttribute('dir', 'rtl');
				}
				this.loader.hide()
			}
			if (!this.selectedLang.rtl && document.body.classList.contains('tjs-rtl-layout')) {
				document.body.classList.remove('tjs-rtl-layout');
				if (elem && elem.length > 0) {
					elem[0].removeAttribute('dir');
				}
			}
			this.setTranslation.next(true);
		}
	}
	/**
	 * Check the app plan permission (PREMIUM_PLANS)
	 * @param name: section name: ['multi-step-form', 'translation', 'discount-bar', 'checklist_notes']
	 * @returns boolean
	 */
	public appPlansPermission(name: string): boolean {
		let status = false;
		let planId = (this._appAdmnStngs && this._appAdmnStngs.package_id) ? this._appAdmnStngs.package_id : '';
		if (planId && ADV_FEATURES.includes(name) && PREMIUM_PLANS.includes(planId)) {
			status = true;
		}
		return status;
	}
	/**
	 * Check the app growing plan permissions (GROWING_PLANS)
	 * @param name: section name: ['checklist_notes']
	 * @returns boolean
	 */
	public appGrowingPlansPerm(name: string): boolean {
		let status = false;
		let planId = (this._appAdmnStngs && this._appAdmnStngs.package_id) ? this._appAdmnStngs.package_id : '';
		if (planId && (GROWING_PLANS.includes(planId) && GROWING_FEATURES.includes(name))) {
			status = true;
		}
		return status;
	}

	/**
	 * Get trial data and start trial if user is not on premium plans
	 */
	public isStarterPlans(name: string): boolean {
		let status: boolean = this.appPlansPermission(name);
		if(!status){
			status = this.appGrowingPlansPerm(name)
		}
		return !status;
	}
	/**
	 * Get the auth header(auth_token and timestamp)
	 * @returns object
	 */
	public async getAuthHeader(): Promise<any> {
		let domainName: any = this._appAdmnStngs?.merchant_settings?.store?.domain_name;
		let date = new Date().getTime();
		let dateString = date ? date.toString() : '';
		let authKey: any = domainName + AUTH_KEY + dateString;
		let authKey1 = await sha512Hash(authKey);
		let authKey2 = await sha512Hash(domainName);
		let authToken: any = authKey1 + '_' + authKey2;
		return { 'Auth-token': authToken, 'Timestamp': dateString };
	}

	// public updatePreferredLang(code: string): void {
	// 	this.loader.show();
	// 	let reqBody: any = {
	// 		id: this._userInfo._id,
	// 		preferred_language: code
	// 	}
	// 	this.apiServ.callApiWithPathVariables('PUT', 'UpdateLanguage', [this._userInfo._id], reqBody).pipe(takeUntil(this.destroy)).subscribe((res: any) => this.updateLanguageRes(res));
	// }
	// private updateLanguageRes(res: any): void {
	// 	if(this.apiServ.checkAPIRes(res)){
	// 		window.location.reload();
	// 	}
	// }

	public setCurrPageIdInBody(pageId: any): void {
		if(pageId){
			let bodyElement: any = document.body;
			bodyElement.setAttribute('data-curr_page_id', pageId.toString());
			// this.autoLoadLeadsPopup();
		}
	}
	// private autoLoadLeadsPopup(): void {
	// 	if(this.appPlansPermission('leads')){
	// 		let leadsScript: any = document.getElementById('auto-load-leads-popup-script');
	// 		if(!leadsScript){
	// 			let scriptElem: any = document.createElement('script');
	// 			// scriptElem.src = this.initServ.baseUrl+'/resources/auto-load-leads-popup.min.js';
	// 			scriptElem.src = this.baseUrl+'/resources/auto-load-leads-popup.js';
	// 			scriptElem.id = 'auto-load-leads-popup-script';
	// 			scriptElem.async = true;
	// 			document.head.appendChild(scriptElem);
	// 		}
	// 		setTimeout(() => {
	// 			if(!IS_DEV){
	// 				(window as any).fetchLeadPopups();
	// 			}
	// 		}, 1000);
	// 	}
	// }

	private setDateFormat(): void{
		if(this._appAdmnStngs && this._appAdmnStngs?.merchant_settings && this?._appAdmnStngs?.merchant_settings?.store && this._appAdmnStngs?.merchant_settings?.store?.date_format){
			this.storeDateFormat = this._appAdmnStngs?.merchant_settings?.store?.date_format;
		}
	}

	/**
	 * Generates the necessary headers for a session authentication request.
	 */
	public async getSessionHeaders(domainName: string = '') {
		// Global variable for !temporarily commented from here to handle security failure callback. When the new security is not stable on production then it will be enabled. We remove this when every think working fine.
		if(ALLOW_OLD_VERSION){
			return await getSessionHeaders(env.arjun+env.bhishma+env.chanakya+env.drona, env.astonmartin+env.bentley);
		} else {
			domainName = domainName ? domainName : this.getDomainName();
			return await generateSessionHeaders(env.audi+env.buick+env.chevrolet+env.dodge, domainName, env.abhimanyu+env.bharata+env.chandra+env.devika);
		}
	}

	/**
	 * Gets the domain name based on the environment base URL.
	 * If the base URL contains 'bookingkoala.com' or 'bookingkoala.co.in', returns the domain name from environment variables.
	 * Otherwise, returns the domain name from the current window location.
	 * @returns The domain name as a string.
	 */
	public getDomainName(): string {
		let hostUrl: string = env.baseUrl;
		if(hostUrl.includes('bookingkoala.com') || hostUrl.includes('bookingkoala.co.in')){
			return env.domainName;
		}
		return window.location.host;
	}

	/**
	 * Method makes a request to the 'DesignSett' API to get the global design settings object in the response.
	 */
	public applyGlobalStyleApi(): void {
		this.apiServ.callApi('GET', 'DesignSett').pipe(takeUntil(this.destroy)).subscribe((res: any) => this.applyGlobalStyleApiRes(res));
	}

	/**
	 * Response handler for `applyGlobalStyleApi` method which applies custom font settings, colors, and CSS styles to a web.
	 * page based on data received from an API response.
	 * @param {any} res - The `res` parameter object that contains data related to design settings ie, font settings, color settings, and custom CSS for styling elements on a web page.
	 * The function processes this data to apply global styles to the page based on
	 */
	private applyGlobalStyleApiRes(res:any):void {
		let data = res?.data;
		if (!data?.design_settings) { return; }
		let style: string = "";
		// destructed fields from object design settings
		let {font_settings, color_settings, custom_css} = data.design_settings;
		// code to apply font size and font family
		if (font_settings?.enable_custom_font) {
			let fontSett = font_settings;
			// self calling block for loading dynamic web fonts link
			(() => {
				const doc: Document = document;
				let wf = doc.createElement('script'), s = doc.scripts[0];
				wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
				wf.async = true;
				wf.onload = () => {
					(window as any).WebFont.load({
						google: {
							families: [fontSett.base_font_type + ':300,400,700,900&display=swap']
						}
					});
				}
				s.parentNode?.insertBefore(wf, s);
			})();
			//  applying type font customization
			if (fontSett.base_font_type) {
				style += 'html,#bkIframe{font-family:' + fontSett.base_font_type + ';}';
			}
			if (fontSett.size) {
				style += '@media (min-width: 992px) {html,#bkIframe {font-size:' + fontSett.size + 'px;}}';
			}
			if (fontSett.mob_size) {
				style += '@media (max-width: 991px) {html,#bkIframe {font-size:' + fontSett.mob_size + 'px;}}';
			}
		}
		// code to apply font colors
		if (color_settings?.enable_custom_colors) {
			let colorSett = color_settings;
			if (colorSett.text_color) {
				style += '#bkIframe{color:' + colorSett.text_color + ';}';
			}
			if (colorSett.link_color) {
				style += 'a{color:' + colorSett.link_color + ';}';
			}
			if (colorSett.active_link_color) {
				style += 'a:active,a:hover{color:' + colorSett.active_link_color + ';}';
			}
			if (colorSett.heading_color) {
				style += 'h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{ color:' + colorSett.heading_color + ';}';
			}
			// Radio/checkbox
			if (colorSett.radio_color) {
				style += '.form-check-input:checked{border-color:' + colorSett.radio_color + ';background-color:' + colorSett.radio_color + ';}'
			}
			// Calendar
			let cal_active_bg_color = (colorSett.cal_active_bg_color) ? colorSett.cal_active_bg_color : '';
			let cal_active_color = (colorSett.cal_active_color) ? colorSett.cal_active_color : '';
			if (cal_active_bg_color || cal_active_color) {
				style += '.tjs-bcalendar__active:not(.tjs-bcalendar__past-date){background:' + cal_active_bg_color + ';color:' + cal_active_color + ';}';
				style += '.tjs-bcalendar__day:hover:not(.tjs-bcalendar__past-date){background:' + cal_active_bg_color + ';color:' + cal_active_color + ';}';
				style += '.tjs-bcalendar__today:not(.tjs-bcalendar__active):hover{background:' + cal_active_bg_color + ';color:' + cal_active_color + ';}';
			}
			// New Style for loader
			if (colorSett?.loader_color) {
				style += '.tjs-loader--v2 .loader .dot{ background : ' + colorSett.loader_color + ';}';
			}
		}
		// custom css
		if (custom_css?.css) {
			style += custom_css.css;
		}
		let existingStyleSheet = document.getElementById('global-style');
		if (existingStyleSheet) {
			existingStyleSheet.parentNode?.removeChild(existingStyleSheet);
		}
		// create style tag
		let styleTag: HTMLStyleElement = document.createElement('style');
		document.body.appendChild(styleTag);
		styleTag.type = 'text/css';
		styleTag.id = 'global-style';
		styleTag.appendChild(document.createTextNode(style));
	}

	/**
	 * Booking customization data, handle by theme builder
	 * @param isEmpty
	 * @param code
	 * @returns Api Res
	 */
	public async loadServCatsAndFreqs(): Promise<any> {
		if(!this.apiLoadStatus['servsAndFreqs']){
			return this.apiServ.callApi('GET', 'ServsAndFreqs').pipe(takeUntil(this.destroy)).toPromise().then((resp: any) => { this.servsAndFreqsApiResp(resp) });
		}else{
			return Promise.resolve();
		}
	}
	private servsAndFreqsApiResp(resp: any): any {
		if(this.apiServ.checkAPIRes(resp) && resp.data) {
			this.allServCats = resp.data.service_category;
			this.allFreqs = resp.data.form_frequencies;
		}
		this.apiLoadStatus['servsAndFreqs'] = true;
	}
}
